import { FC, useMemo, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { Button } from '@/components/ui/button'
import Papa from 'papaparse'
import {
  FileSpreadsheet,
  Users,
  AlertCircle,
  Loader2,
  CheckCircle,
  XCircle,
} from 'lucide-react'
import ModalEditAttendee from '../components/ModalEditAttendee'
import { GET_SALUTATIONS } from '@/graphql/queries'
import { useLazyQuery } from '@apollo/client'
import { getLanguage } from '@/constants/language'
import { Switch } from '@/components/ui/switch'
import { Label } from '@/components/ui/label'

interface ImportStepProps {
  onBack: () => void
  onSubmit: (data: { data: ProcessedRow[]; sendEmail: boolean }) => void
  file: File | null
  columnMapping: Record<string, string>
  charset: string
  attendeeProperties: Record<string, string>[]
  importResults: {
    succeeded: any[]
    failed: any[]
  }
  isImporting: boolean
}

interface ImportSummary {
  totalRows: number
  validRows: number
  issues: string[]
  previewRows: ProcessedRow[]
}

export interface ProcessedRow {
  firstName: string
  lastName: string
  email: string
  phone?: string
  company?: string
  title?: string
  [key: string]: string | undefined
}

export const ImportStep: FC<ImportStepProps> = ({
  onBack,
  onSubmit,
  file,
  columnMapping,
  charset,
  attendeeProperties,
  importResults,
  isImporting,
}) => {
  const { t } = useTranslation()
  const [isAnalyzing, setIsAnalyzing] = useState(false)

  const [summary, setSummary] = useState<ImportSummary>({
    totalRows: 0,
    validRows: 0,
    issues: [],
    previewRows: [],
  })

  const [editingRow, setEditingRow] = useState<ProcessedRow | null>(null)
  const [editingRowIndex, setEditingRowIndex] = useState<number | null>(null)

  const [sendEmail, setSendEmail] = useState(false)

  const summaryPromise = useMemo(() => {
    return new Promise<ImportSummary>(resolve => {
      const result: ImportSummary = {
        totalRows: 0,
        validRows: 0,
        issues: [],
        previewRows: [],
      }

      if (!file) {
        resolve(result)
        return
      }

      Papa.parse(file, {
        header: true,
        skipEmptyLines: true,
        encoding: charset,
        complete: results => {
          const processedRows = results.data
            .map((row: any) => {
              const processedRow: ProcessedRow = {} as ProcessedRow
              Object.entries(columnMapping).forEach(([propKey, csvColumn]) => {
                processedRow[propKey] = row[csvColumn]?.trim() || ''
              })
              return processedRow
            })
            .filter(row =>
              Object.values(row).some(value => value && value.trim() !== '')
            )

          result.totalRows = processedRows.length
          result.previewRows = processedRows

          processedRows.forEach((row, index) => {
            const rowIssues: string[] = []

            Object.entries(columnMapping).forEach(([propKey, csvColumn]) => {
              const value = row[propKey]?.trim() || ''

              // console.log(
              //   'propKey: ',
              //   propKey,
              //   ' - value: ',
              //   value,
              //   ' - csvColumn: ',
              //   csvColumn
              // )

              if (['email'].includes(propKey) && !value) {
                rowIssues.push(
                  t('import_attendees.import_step.missing', { propKey })
                )
              }

              if (propKey === 'email' && value) {
                const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/
                if (!emailRegex.test(value)) {
                  rowIssues.push(
                    t('import_attendees.import_step.invalid_email_format')
                  )
                }
              }
            })

            if (rowIssues.length === 0) {
              result.validRows++
            } else {
              result.issues.push(
                t('import_attendees.import_step.row_issues', {
                  index: index + 2,
                  issues: rowIssues.join(', '),
                })
              )
            }
          })

          resolve(result)
        },
        error: error => {
          result.issues.push(
            t('import_attendees.import_step.parse_error', {
              error: error.message,
            })
          )
          resolve(result)
        },
      })
    })
  }, [file, columnMapping])

  useEffect(() => {
    const getSummary = async () => {
      setIsAnalyzing(true)
      const result = await summaryPromise
      setSummary(result)
      setIsAnalyzing(false)
    }
    getSummary()
  }, [summaryPromise])

  const handleImport = async () => {
    try {
      onSubmit({ data: summary.previewRows, sendEmail })
    } catch (error) {
      console.error('Import failed:', error)
    }
  }

  const appLanguage = localStorage.getItem('i18nextLng')
  const [salutationList, setSalutationList] = useState<
    { key: string; value: string }[]
  >([])

  const [getSalutationList, { data: salutationData }] = useLazyQuery(
    GET_SALUTATIONS,
    {
      onCompleted: () => {
        setSalutationList(salutationData?.fetchSalutations?.data)
      },
      onError: ({ message }) => {
        console.log(message)
      },
      variables: {
        language: getLanguage(appLanguage),
      },
    }
  )
  useEffect(() => {
    getSalutationList()
  }, [appLanguage])

  const handleRowUpdate = (updatedRow: ProcessedRow) => {
    if (editingRowIndex === null) return

    const newPreviewRows = [...summary.previewRows]
    newPreviewRows[editingRowIndex] = updatedRow

    setSummary({
      ...summary,
      previewRows: newPreviewRows,
    })

    setEditingRow(null)
    setEditingRowIndex(null)
  }

  return (
    <div className='space-y-6'>
      <div className='space-y-2'>
        <h2 className='text-2xl font-semibold'>
          {t('import_attendees.import_step.title')}
        </h2>
        <p className='text-muted-foreground'>
          {t(
            'import_attendees.import_step.review_your_import_before_proceeding'
          )}
        </p>
      </div>

      <div className='rounded-lg border p-6 space-y-6'>
        {/* File Info */}
        <div className='flex items-center gap-4'>
          <div className='h-12 w-12 rounded-full bg-primary/10 flex items-center justify-center'>
            <FileSpreadsheet className='h-6 w-6 text-primary' />
          </div>
          <div>
            <h3 className='font-medium'>
              {file?.name || t('import_attendees.unknown_file')}
            </h3>
            <p className='text-sm text-muted-foreground'>
              {file?.size
                ? `${(file.size / 1024).toFixed(1)} KB`
                : t('import_attendees.unknown_size')}
            </p>
          </div>
        </div>

        {/* Import Summary */}
        <div className='space-y-4'>
          {isAnalyzing ? (
            <div className='flex items-center gap-2 text-muted-foreground'>
              <Loader2 className='h-5 w-5 animate-spin' />
              <p>{t('import_attendees.import_step.analyzing_file')}</p>
            </div>
          ) : (
            <div className='flex items-center gap-4'>
              <Users className='h-5 w-5 text-muted-foreground' />
              <div>
                <p className='font-medium'>
                  {t('import_attendees.import_step.attendees_found', {
                    count: summary.totalRows,
                  })}
                </p>
                <p className='text-sm text-muted-foreground'>
                  {t('import_attendees.import_step.valid_attendees_to_import', {
                    count: summary.validRows,
                  })}
                </p>
              </div>
            </div>
          )}

          {/* Issues Section */}
          {summary.issues.length > 0 && (
            <div className='rounded-lg bg-yellow-50 p-4'>
              <div className='flex items-center gap-2 text-yellow-800'>
                <AlertCircle className='h-5 w-5' />
                <h4 className='font-medium'>
                  {t('import_attendees.import_step.issues_found')}
                </h4>
              </div>
              <ul className='mt-2 space-y-1 text-sm text-yellow-700'>
                {summary.issues.slice(0, 3).map((issue, index) => (
                  <li key={index}>{issue}</li>
                ))}
                {summary.issues.length > 3 && (
                  <li>
                    {t('import_attendees.import_step.and_count_more_issues', {
                      count: summary.issues.length - 3,
                    })}
                  </li>
                )}
              </ul>
            </div>
          )}
        </div>
      </div>

      <div className='flex items-center space-x-2 pb-4 border-b'>
        <Switch
          id='send-email'
          checked={sendEmail}
          onCheckedChange={setSendEmail}
        />
        <Label htmlFor='send-email' className='text-sm text-muted-foreground'>
          {t(
            'import_attendees.import_step.send_welcome_email_to_imported_attendees'
          )}
        </Label>
      </div>

      {/* Preview Table */}
      <div className='rounded-lg border'>
        <div className='overflow-x-auto'>
          <table className='w-full text-sm'>
            <thead className='bg-muted/50'>
              <tr>
                {Object.entries(columnMapping).map(([propKey]) => (
                  <th key={propKey} className='p-3 text-left font-medium'>
                    {t(
                      `${
                        attendeeProperties.find(p => p.key === propKey)
                          ?.label || propKey
                      }`
                    )}
                  </th>
                ))}
                <th className='p-3 text-left font-medium'>
                  {t('import_attendees.actions')}
                </th>
              </tr>
            </thead>
            <tbody>
              {summary.previewRows?.slice(0, 5).map((row, index) => (
                <tr key={index} className='border-t'>
                  {Object.entries(columnMapping).map(([propKey]) => (
                    <td key={propKey} className='p-3'>
                      {row[propKey] || ''}
                    </td>
                  ))}
                  <td className='p-3'>
                    <Button
                      variant='outline'
                      size='sm'
                      onClick={() => {
                        setEditingRow(row)
                        setEditingRowIndex(index)
                      }}
                    >
                      {t('Edit')}
                    </Button>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
        {summary.totalRows > 5 && (
          <div className='border-t p-3 text-center text-sm text-muted-foreground'>
            {t('import_attendees.import_step.and_count_more_rows', {
              count: summary.totalRows - 5,
            })}
          </div>
        )}
      </div>

      <div className='space-y-4'>
        {importResults.succeeded.length > 0 && (
          <div className='rounded-lg border p-4'>
            <h3 className='font-medium text-green-700 flex items-center gap-2 mb-3'>
              <CheckCircle className='h-5 w-5' />
              {t('import_attendees.import_step.successful_imports', {
                count: importResults.succeeded.length,
              })}
            </h3>
            <div className='overflow-x-auto'>
              <table className='w-full text-sm'>
                <thead className='bg-muted/50'>
                  <tr>
                    {['email', 'firstName', 'lastName'].map(propKey => (
                      <th key={propKey} className='p-3 text-left font-medium'>
                        {t(
                          `${
                            attendeeProperties.find(p => p.key === propKey)
                              ?.label || propKey
                          }`
                        )}
                      </th>
                    ))}
                  </tr>
                </thead>
                <tbody>
                  {importResults.succeeded.map((row, index) => (
                    <tr key={index} className='border-t'>
                      {['email', 'firstName', 'lastName'].map(propKey => (
                        <td key={propKey} className='p-3'>
                          {row[propKey] || ''}
                        </td>
                      ))}
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          </div>
        )}

        {importResults.failed.length > 0 && (
          <div className='rounded-lg border p-4'>
            <h3 className='font-medium text-red-700 flex items-center gap-2 mb-3'>
              <XCircle className='h-5 w-5' />
              {t('import_attendees.import_step.failed_imports', {
                count: importResults.failed.length,
              })}
            </h3>
            <div className='overflow-x-auto'>
              <table className='w-full text-sm'>
                <thead className='bg-muted/50'>
                  <tr>
                    {['email'].map(propKey => (
                      <th key={propKey} className='p-3 text-left font-medium'>
                        {t(
                          `${
                            attendeeProperties.find(p => p.key === propKey)
                              ?.label || propKey
                          }`
                        )}
                      </th>
                    ))}
                    <th className='p-3 text-left font-medium'>
                      {t('import_attendees.import_step.error')}
                    </th>
                  </tr>
                </thead>
                <tbody>
                  {importResults.failed.map((row, index) => (
                    <tr key={index} className='border-t'>
                      <td key={index} className='p-3'>
                        {row || ''}
                      </td>
                      <td className='p-3 text-red-600'>
                        {row.error ||
                          t('import_attendees.import_step.already_exists')}
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          </div>
        )}
      </div>

      <div className='flex justify-between pt-4'>
        <Button variant='outline' onClick={onBack} className='px-6'>
          {t('Back')}
        </Button>
        <Button
          onClick={handleImport}
          className='bg-primary px-6'
          disabled={summary.validRows === 0 || isImporting}
        >
          {isImporting ? (
            <div className='flex items-center gap-2'>
              <Loader2 className='h-4 w-4 animate-spin' />
              {t('import_attendees.import_step.importing')}
            </div>
          ) : (
            t('import_attendees.import_step.import')
          )}
        </Button>
      </div>

      <ModalEditAttendee
        isShow={editingRow !== null}
        hideModal={() => {
          setEditingRow(null)
          setEditingRowIndex(null)
        }}
        attendeeInfo={editingRow}
        onSubmit={handleRowUpdate}
        salutationList={salutationList}
      />
    </div>
  )
}
