import { FC } from 'react'
import { Redirect, useLocation } from 'utils/adapters'
import { useAppSelector } from 'utils/hooks'
import { PATH_NAME } from '../../routes/routesMap'

const AuthGuard: FC = ({ children }) => {
  const { isAuth } = useAppSelector(state => state.authenticationReducer)
  const location = useLocation()

  // Save the attempted URL if not already on login page
  if (!isAuth && location.pathname !== PATH_NAME.LOGIN) {
    sessionStorage.setItem('redirectUrl', location.pathname + location.search)
  }

  return isAuth ? <>{children}</> : <Redirect to={PATH_NAME.LOGIN} />
}

export default AuthGuard
