import { Dialog, DialogContent } from '@/components/ui/dialog'
import { IEvent } from '@/models/IEvent'
import { FC } from 'react'
import ClientEventDetail from '../../ClientEventDetail/ClientEventDetail'

type EventDetailPreviewProps = {
  open: boolean
  onClose: any
  event: IEvent
}
const EventDetailPreview: FC<EventDetailPreviewProps> = ({
  open,
  onClose,
  event,
}) => {
  return (
    <Dialog open={open} onOpenChange={onClose}>
      <DialogContent className='max-h-[90vh] overflow-y-auto w-full max-w-[90%] p-2 z-[9999]'>
        <ClientEventDetail event={event as IEvent} isPreview={true} />
      </DialogContent>
    </Dialog>
  )
}

export default EventDetailPreview
