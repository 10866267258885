// Create a new utility file for attendee field handling
export const getAttendeeSystemField = (
  attendee: any,
  event: any,
  systemField: string,
  fallbackField: string
) => {
  const customField = event?.customSelections?.find(
    cs => cs.systemField === systemField
  )

  let value = customField
    ? attendee?.customSelection?.[customField.id]
    : attendee[fallbackField]

  if (!value) {
    value = attendee[fallbackField]
  }

  return value || ''
}

// Helper for getting accessor function for table columns
export const getSystemFieldAccessor = (
  event: any,
  fieldName: string,
  fallbackField: string
) => {
  return (row: any) => {
    return getAttendeeSystemField(row, event, fieldName, fallbackField)
  }
}
