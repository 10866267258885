import Permission from '@/components/atoms/Permission'
import SidebarPermission from '@/components/layouts/AdminLayout/SidebarPermission'
import { Button } from '@/components/ui/button'
import { Icons } from '@/components/ui/icon'
import { Label } from '@/components/ui/label'
import {
  Popover,
  PopoverContent,
  PopoverTrigger,
} from '@/components/ui/popover'
import { Separator } from '@/components/ui/separator'
import { COLORS } from '@/constants/colors'
import { USER_ROLE } from '@/constants/userRole'
import { cn } from '@/lib/utils'
import { Attendee } from '@/models/IEvent'
import { IStatus } from '@/models/IStatus'
import { PATH_NAME } from '@/routes/routesMap'
import { useHistory } from '@/utils/adapters'
import {
  STATUS,
  isAttendeeCancelled,
  renderStatusBgColor,
  renderStatusLabel,
} from '@/utils/helpers'
import { Check, ChevronRight, Settings } from 'lucide-react'
import { FC, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'

type UpdateAttendeeStatusModalProps = {
  status: string
  statusList: { value: string; label: string; quantity?: number }[]
  customStatus: IStatus[]
  updateStatus: (
    attendee: Attendee,
    status: string,
    onClose: () => void
  ) => void
  attendee: Attendee
  className?: string
}

export const UpdateAttendeeStatusModal: FC<UpdateAttendeeStatusModalProps> = ({
  status,
  statusList,
  customStatus,
  updateStatus,
  attendee,
  className,
}) => {
  const { t } = useTranslation()
  const history = useHistory()
  const [open, setOpen] = useState(false)
  const [loading, setLoading] = useState(false)
  const [activeStatus, setActiveStatus] = useState<string>(status)

  useEffect(() => {
    setActiveStatus(status)
  }, [status])

  const getCustomColorStatus = () => {
    const color = customStatus.find(
      (item: IStatus) => item.name === activeStatus
    )?.bgColor

    return color || COLORS.BLUE
  }

  return (
    <div className={cn('w-[181px] flex', className)}>
      <Popover open={open} onOpenChange={setOpen}>
        <PopoverTrigger asChild>
          <div className='flex'>
            <Button
              className={'rounded-none rounded-l-lg border-r-0'}
              size='sm'
              style={{
                backgroundColor: Object.values(STATUS).includes(activeStatus)
                  ? renderStatusBgColor(activeStatus)
                  : getCustomColorStatus(),
              }}
            >
              {loading && (
                <Icons.spinner className='mr-2 h-4 w-4 animate-spin' />
              )}
              {renderStatusLabel(activeStatus)}
            </Button>
            <Button
              size='sm'
              style={{
                backgroundColor: Object.values(STATUS).includes(activeStatus)
                  ? renderStatusBgColor(activeStatus)
                  : getCustomColorStatus(),
              }}
              className='rounded-none rounded-r-lg p-2 border-l border-l-slate-300'
            >
              <ChevronRight width={16} />
            </Button>
          </div>
        </PopoverTrigger>
        <PopoverContent className='w-80 max-h-[500px] overflow-y-scroll'>
          <div className='grid gap-4'>
            <div className='space-y-2'>
              <h2 className='font-bold leading-none text-center'>
                {t('update_attendee_status_modal.title')}
              </h2>
            </div>
            <div className='flex flex-col gap-4'>
              {statusList.map(item => {
                return (
                  <div
                    key={item.value}
                    className={`flex justify-between cursor-pointer ${
                      isAttendeeCancelled(item.value)
                        ? 'cursor-not-allowed opacity-40'
                        : ''
                    }`}
                    onClick={async () => {
                      const callback = () => {
                        setOpen(false)
                        setActiveStatus(item.value)
                      }
                      if (!isAttendeeCancelled(item.value)) {
                        setLoading(true)
                        await updateStatus(attendee, item.value, callback)
                        setLoading(false)
                      }
                    }}
                  >
                    <div className='flex gap-4'>
                      <Icons.status
                        id={item.value}
                        className='mr-2 h-4 w-4'
                        color={renderStatusBgColor(item.value)}
                      />
                      <Label
                        className={`cursor-pointer ${
                          isAttendeeCancelled(item.value)
                            ? 'cursor-not-allowed opacity-40'
                            : ''
                        }`}
                      >
                        {item.label}
                      </Label>
                    </div>
                    {status === item.value && (
                      <Check
                        width={16}
                        height={16}
                        color={COLORS.BLUE}
                        strokeWidth={3}
                        className='mr-10'
                      />
                    )}
                  </div>
                )
              })}
              <Separator className='my-1' />
              <div className='flex items-center justify-between'>
                <div>
                  {t(
                    'update_attendee_status_modal.custom_status_section.title'
                  )}
                </div>
                <SidebarPermission
                  allowedRoles={[USER_ROLE.ROOT_ADMIN, USER_ROLE.ADMIN]}
                >
                  <Settings
                    className='cursor-pointer'
                    width={20}
                    onClick={() => history.push(PATH_NAME.STATUS_MANAGEMENT)}
                  />
                </SidebarPermission>
              </div>

              {customStatus.map((item: IStatus) => {
                return (
                  <div
                    key={item._id}
                    className='flex justify-between cursor-pointer'
                    onClick={async () => {
                      const callback = () => {
                        setOpen(false)
                        setActiveStatus(item.name)
                      }

                      setLoading(true)
                      await updateStatus(attendee, item.name, callback)
                      setLoading(false)
                    }}
                  >
                    <div className='flex gap-4'>
                      <Icons.status
                        className='mr-2 h-4 w-4'
                        color={item.bgColor}
                        id={item._id}
                      />
                      <Label className='cursor-pointer'>{item.name}</Label>
                    </div>
                    {status === item.name && (
                      <Check
                        width={16}
                        height={16}
                        color={COLORS.BLUE}
                        strokeWidth={3}
                        className='mr-10'
                      />
                    )}
                  </div>
                )
              })}
            </div>
          </div>
        </PopoverContent>
      </Popover>
    </div>
  )
}
