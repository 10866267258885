import { FC, useEffect, useRef, useState } from 'react'
import { useTranslation } from 'react-i18next'

import { BadgeCurrency } from '@/components/atoms/BadgeCurrency'
import { Button } from '@/components/ui/button'
import { toast } from '@/components/ui/use-toast'
import { COLORS } from '@/constants/colors'
import { EventLayoutType, EventType } from '@/constants/events'
import { Environment } from '@/environments'
import { cn } from '@/lib/utils'
import {
  hexToRgb,
  setTextColorBasedOnBackground,
  isSimilarColor,
} from '@/utils/helpers/colors'
import { Background } from 'assets/images'
import LanguageDropdown from 'components/organisms/language/language-dropdown'
import DOMPurify from 'dompurify'
import { CalendarDays, Clock, MapPin, Share2 } from 'lucide-react'
import { IEvent } from 'models'
import { CopyToClipboard } from 'react-copy-to-clipboard'
import { useHistory, useParams } from 'utils/adapters'
import {
  checkCapacity,
  defaultTimezone,
  getEventDateByTimeZone,
  getEventTime,
  priceFormat,
  transformAddress,
} from 'utils/helpers'

interface ParamTypes {
  id: string
}

interface IProps {
  event: IEvent
  isPreview?: boolean
}

const ClientEventDetail: FC<IProps> = ({ event, isPreview = false }) => {
  const { t } = useTranslation()

  const { id } = useParams<ParamTypes>()
  const history = useHistory()

  const { checkMaxRegister, checkSubTitle } = checkCapacity()
  const lang = localStorage.getItem('i18nextLng') as string

  const contentRef = useRef<HTMLDivElement>(null)
  const [contentHeight, setContentHeight] = useState<number | null>(null)
  const defaultBackground = Background

  const isTwoSidesLayout =
    event?.eventSetting?.layoutType !== EventLayoutType.CLASSIC

  useEffect(() => {
    if (contentRef.current) {
      setContentHeight(contentRef.current.clientHeight)
    }
  }, [contentRef.current])

  const isDigitalEvent = event?.type === EventType.DIGITAL
  let tz = event?.timezone || defaultTimezone

  if (isDigitalEvent) {
    const { timeZone: clientTimeZone } = Intl.DateTimeFormat().resolvedOptions()
    tz = clientTimeZone
  }

  const { isTheSameDay, startTime, endTime, utcOffset, isTheSameTime } =
    getEventTime(event?.mainLanguage, tz, event?.startEvent, event?.endEvent)

  const fontColor = event?.eventSetting?.fontColor || COLORS.BLUE
  const primaryColor = event?.eventSetting?.primaryColor || COLORS.BLUE
  const bgColor = hexToRgb(event?.eventSetting?.bgColor || '#ffffff')
  const bgColorOpacity = event?.eventSetting?.bgColorOpacity
  const backgroundTransparent = event?.eventSetting?.backgroundTransparent

  const subTitle = checkSubTitle(
    event?.isExpired,
    event?.capacity,
    event?.attendeesCount,
    event?.eventSetting?.isLockedRegister
  )
  const logoWidth = event?.eventSetting?.logoSize?.split('x')[0]

  const isPrimaryColorSimilarToBgColor = isSimilarColor(
    primaryColor,
    event?.eventSetting?.bgColor || '#ffffff'
  )

  const isShowPrice =
    event?.eventSetting?.isShowPrice === null ||
    event?.eventSetting?.isShowPrice === true

  const detailBackgroundColor = isPrimaryColorSimilarToBgColor
    ? setTextColorBasedOnBackground(event?.eventSetting?.primaryColor)
    : event?.eventSetting?.primaryColor

  useEffect(() => {
    const timerId = setTimeout(() => {
      if (contentRef.current) {
        setContentHeight(contentRef.current?.clientHeight)
      }
    }, 100)

    return () => {
      if (timerId) {
        clearTimeout(timerId)
      }
    }
  }, [])

  const onCopy = () => {
    toast({
      description: t('Copied to clipboard!'),
    })
  }

  const onClick = () => {
    sessionStorage.setItem('currentEvent', JSON.stringify(event))
    localStorage.setItem(
      'clientEventDetailUrl',
      JSON.stringify({ clientEventPath: `/attendee/register/${id}` })
    )
    history.push(`/attendee/register/${id}`)
  }

  return (
    <div
      className='min-h-screen lg:h-screen my-auto bg-center bg-no-repeat bg-cover relative'
      style={{
        backgroundImage: isTwoSidesLayout
          ? 'none'
          : `url(${
              event?.eventSetting?.backgroundPicture || defaultBackground
            })`,
        color: fontColor,
      }}
    >
      {/* overlay for background */}
      {!isTwoSidesLayout && (
        <div
          className='absolute inset-0 bg-white'
          style={{
            opacity: 1 - Number(backgroundTransparent),
          }}
        />
      )}

      <div className='absolute top-1 right-4 md:top-2 md:right-8 lg:top-10 lg:right-14'>
        <LanguageDropdown />
      </div>
      <div
        className={cn(
          'flex bg-white p-5 pb-10 md:p-8 gap-8 min-h-screen lg:h-full flex-col lg:flex-row'
        )}
        style={{
          backgroundColor: isTwoSidesLayout
            ? `rgba(${bgColor?.r}, ${bgColor?.g}, ${bgColor?.b}, ${bgColorOpacity})`
            : 'transparent',
        }}
      >
        {/* Left Section - Image */}
        {isTwoSidesLayout && (
          <div
            className='w-full mt-4 md:mt-0 h-[300px] md:h-[500px] lg:h-full lg:w-2/5 bg-cover bg-center bg-no-repeat'
            style={{
              backgroundImage: `url(${
                event?.eventSetting?.backgroundPicture || defaultBackground
              })`,
              opacity: 1 - Number(backgroundTransparent),
            }}
          />
        )}

        {/* Right Section - Details */}
        <div
          className={cn(
            'relative',
            isTwoSidesLayout ? 'w-full lg:w-3/5' : 'w-full'
          )}
        >
          <div ref={contentRef}>
            <div className='relative'>
              {event?.eventSetting?.logo && (
                <img
                  src={event?.eventSetting?.logo}
                  alt='Thumbnail 2'
                  className={cn(
                    'object-cover',
                    logoWidth ? `${logoWidth}px` : 'w-1/2 md:w-1/4'
                  )}
                  width={logoWidth || '0%'}
                />
              )}
            </div>
            <div className='mb-4 md:mb-6'>
              <h1
                className={cn(
                  'mt-4 font-semibold',
                  event?.title && event?.title?.length > 40
                    ? 'text-xl md:text-3xl'
                    : event?.title && event?.title?.length > 20
                    ? 'text-3xl md:text-4xl'
                    : 'text-4xl md:text-5xl'
                )}
              >
                {event?.title}
              </h1>
            </div>

            <div className='flex flex-col gap-2'>
              {!isDigitalEvent ? (
                <>
                  <div className='flex items-center gap-2 justify-start'>
                    <div className='w-[16px]'>
                      <MapPin width='16' height='16' />
                    </div>
                    <p className='text-base'>
                      {event?.hotelName ? `${event?.hotelName}, ` : ''}
                      {transformAddress(
                        event?.address?.street as string,
                        '',
                        event?.address?.city as string,
                        event?.address?.country as string
                      )}
                    </p>
                  </div>
                  <div className='flex items-center gap-2 justify-start	'>
                    <div className='w-[16px]'>
                      <CalendarDays width='16' height='16' />
                    </div>
                    <p className='text-base'>
                      {getEventDateByTimeZone(event?.startEvent, lang, tz)}
                      {!isTheSameDay &&
                        ` - ${getEventDateByTimeZone(
                          event?.endEvent,
                          lang,
                          tz
                        )}`}
                    </p>
                  </div>
                  {isTheSameDay && (
                    <div className='flex items-center gap-2 justify-start'>
                      <Clock width='16' height='16' />
                      <p className='text-base'>
                        {isTheSameTime
                          ? `${startTime} (GMT${utcOffset})`
                          : `${startTime} - ${endTime} (GMT${utcOffset})`}
                      </p>
                    </div>
                  )}
                </>
              ) : (
                <>
                  <div className='flex items-center gap-2 justify-start	'>
                    <CalendarDays width='16' height='16' />
                    <p className='text-base'>
                      {getEventDateByTimeZone(event?.startEvent, lang, tz)}{' '}
                      {!isTheSameDay &&
                        ` - ${getEventDateByTimeZone(
                          event?.endEvent,
                          lang,
                          tz
                        )}`}
                    </p>
                  </div>
                  {isTheSameDay && (
                    <div className='flex items-center gap-2 justify-start'>
                      <Clock width='16' height='16' />
                      <p className='text-base'>
                        {`${startTime} - ${endTime} (GMT${utcOffset})`}
                      </p>
                    </div>
                  )}
                </>
              )}
              {event?.isBookable && event?.price !== 0 && isShowPrice && (
                <div className='flex items-center gap-2 justify-start'>
                  <BadgeCurrency currency={event?.currency} className='' />
                  <span className=''>
                    {priceFormat(event?.price, lang)} / {t('person')}
                  </span>
                </div>
              )}
            </div>
          </div>

          <div
            className='my-4 pr-4 overflow-x-auto mt-2 attendee-detail scrollbar-custom flex-grow max-h-none event-detail-description-container'
            style={
              {
                '--content-height': `${contentHeight}px`,
              } as React.CSSProperties
            }
          >
            {event?.description && (
              <div
                dangerouslySetInnerHTML={{
                  __html: DOMPurify.sanitize(event?.description) as string,
                }}
                className={cn('text-base leading-8')}
              />
            )}
          </div>
          <div className='bottom-0 w-full flex justify-between items-center gap-2 lg:absolute'>
            <Button
              disabled={
                !checkMaxRegister(event?.capacity, event?.attendeesCount) ||
                event?.eventSetting?.isLockedRegister
              }
              type='submit'
              onClick={isPreview ? undefined : onClick}
              style={{
                backgroundColor: detailBackgroundColor,
                color: setTextColorBasedOnBackground(detailBackgroundColor),
              }}
              className='w-[90%]'
              size='lg'
            >
              {subTitle ? (
                <p className='text-sm text-muted-foreground'>{subTitle}</p>
              ) : event?.isBookable ? (
                `${t('Book now')}`
              ) : (
                `${t('Register now')}`
              )}
            </Button>
            <CopyToClipboard
              text={`${Environment.registerEventPage}/events/${id}`}
              onCopy={isPreview ? undefined : onCopy}
            >
              <Button variant='outline' className='h-11'>
                <Share2 className='text-[#8e8e8e]' />
              </Button>
            </CopyToClipboard>
          </div>
        </div>
      </div>
      <div
        className='mt-auto text-center text-sm h-8 absolute bottom-0 left-0 w-full px-4 md:px-8 lg:px-14'
        style={{
          color: detailBackgroundColor,
        }}
      >
        <div className='flex flex-wrap justify-between gap-4 px-0 sm:px-2 md:px-32 h-full items-center'>
          <ul className='flex list-none m-0 p-0 justify-between gap-4 lg:gap-8'>
            <li className='flex items-center'>
              <span
                className='text-xs transition-colors duration-250 ease-in uppercase opacity-90 cursor-pointer sm:text-sm'
                onClick={() => history.push('/imprint')}
              >
                {t('Imprint')}
              </span>
            </li>

            <li className='flex items-center'>
              <span
                className='text-xs transition-colors duration-250 ease-in uppercase opacity-90 cursor-pointer sm:text-sm'
                onClick={() => history.push('/privacy')}
              >
                {t('Privacy')}
              </span>
            </li>
          </ul>
          <div></div>
          <div className='text-xs sm:text-sm'>
            ©{new Date().getFullYear()} ConferenceDirect
          </div>
        </div>
      </div>
    </div>
  )
}

export default ClientEventDetail
