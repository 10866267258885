export const SystemFieldType = {
  FirstName: 'firstName',
  LastName: 'lastName',
  // Salutation: 'salutation',
  // AcademicTitle: 'professional',
  JobTitle: 'jobTitle',
  Company: 'company',
  // AddressStreet: 'addressStreet',
  // AddressZipCode: 'addressZipCode',
  // AddressCity: 'addressCity',
  // AddressCountry: 'addressCountry',
  WorkPhone: 'workPhone',
  MobilePhone: 'mobilePhone',
  Fax: 'fax',
}
