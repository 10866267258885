import { FC } from 'react'
import {
  Select,
  SelectContent,
  SelectGroup,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from './select'
import { useTranslation } from 'react-i18next'
import { cn } from '@/lib/utils'
import { FormLabel } from '@/components/ui/final-form'
import { X } from 'lucide-react'

export const SelectAdapter: FC<{
  input: any
  styles?: any
  meta: any
  options: any
  title?: string
  isRequired?: boolean
  disabled?: boolean
  className?: string
  isClearable?: boolean
}> = ({
  input,
  styles,
  meta,
  options,
  title,
  isRequired,
  disabled = false,
  className,
  isClearable = false,
}) => {
  const { t } = useTranslation()

  return (
    <div>
      {title && (
        <FormLabel meta={meta} className={cn(className, 'mb-2')}>
          {t(title)} {isRequired && '*'}
        </FormLabel>
      )}
      <div className='relative'>
        <Select onValueChange={input.onChange} {...input} disabled={disabled}>
          <SelectTrigger
            className={cn(
              meta?.error && meta?.touched && 'border-rose-500',
              'border focus:ring-transparent',
              isClearable && input.value && 'pr-8'
            )}
          >
            <SelectValue placeholder={t('Select')} />
          </SelectTrigger>
          <SelectContent>
            <SelectGroup>
              {options.map(
                (item: { label: string; value: string }, index: number) => (
                  <SelectItem key={index} value={item.value}>
                    <p className='font-medium'>{item.label}</p>
                  </SelectItem>
                )
              )}
            </SelectGroup>
          </SelectContent>
        </Select>
        {isClearable && input.value && (
          <button
            type='button'
            onClick={e => {
              e.stopPropagation()
              input.onChange(undefined)
            }}
            className='absolute right-2 top-1/2 -translate-y-1/2 p-1 rounded-full hover:bg-slate-100 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-white focus:ring-slate-400'
          >
            <X className='h-4 w-4 text-slate-500' />
          </button>
        )}
      </div>
    </div>
  )
}
