import React, { FC, useEffect } from 'react'
import { useTranslation } from 'react-i18next'

import {
  useAsyncDebounce,
  useFilters,
  useGlobalFilter,
  useRowSelect,
  useTable,
} from 'react-table'

import { GET_EVENT_ATTENDEES } from 'graphql/queries'
import { setSelectedRecipients } from 'store/User'

import { useParams, useQuery } from 'utils/adapters'
import { useAppDispatch, useAppSelector } from 'utils/hooks'

import InputNoTransition from 'components/atoms/InputNoTransition'
import Loading from 'components/atoms/Loading'

import { FilterIcon } from '@/assets/images'
import {
  Popover,
  PopoverContent,
  PopoverTrigger,
} from '@/components/ui/popover'
import { AttendeeStatusLabel, renderStatusLabel } from '@/utils/helpers'
import { Checkbox } from '@/components/ui/checkbox'
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableHeader,
  TableRow,
} from '@/components/ui/table'
import { FormLabel } from '@/components/ui/final-form'
import { IEvent } from '@/models'
import {
  getAttendeeSystemField,
  getSystemFieldAccessor,
} from '@/lib/utils/attendeeFields'
import { SystemFieldType } from '@/constants/systemField'

function GlobalFilter({
  preGlobalFilteredRows,
  globalFilter,
  setGlobalFilter,
}: any) {
  const count = preGlobalFilteredRows.length
  const [value, setValue] = React.useState(globalFilter)
  const onChange = useAsyncDebounce(value => {
    setGlobalFilter(value || undefined)
  }, 200)
  const { t } = useTranslation()

  return (
    <InputNoTransition
      value={value || ''}
      onChange={e => {
        setValue(e.target.value)
        onChange(e.target.value)
      }}
      placeholder={t('{{count}} records...', { count: count })}
      label={t('Search')}
      type={''}
    />
  )
}

function DefaultColumnFilter({
  column: { filterValue, preFilteredRows, setFilter },
}: any) {
  const { t } = useTranslation()

  const count = preFilteredRows.length

  return (
    <InputNoTransition
      value={filterValue || ''}
      onChange={e => {
        setFilter(e.target.value || undefined)
      }}
      placeholder={t('Search {{count}} records...', { count: count })}
      label='Search'
      type={''}
    />
  )
}

function AttendeeTable({ columns, data }: any) {
  const dispatch = useAppDispatch()
  const defaultColumn = React.useMemo(
    () => ({
      Filter: DefaultColumnFilter,
      width: 'auto',
    }),
    []
  )

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    rows,
    prepareRow,
    state,
    visibleColumns,
    preGlobalFilteredRows,
    setGlobalFilter,
    state: { selectedRowIds },
  } = useTable(
    {
      columns,
      data,
      defaultColumn,
    },
    useFilters,
    useGlobalFilter,
    useRowSelect,
    hooks => {
      hooks.visibleColumns.push(columns => [
        {
          id: 'selection',
          Header: ({ getToggleAllRowsSelectedProps }) => (
            <div>
              <IndeterminateCheckbox {...getToggleAllRowsSelectedProps()} />
            </div>
          ),
          Cell: ({ row }) => (
            <div>
              <IndeterminateCheckbox {...row.getToggleRowSelectedProps()} />
            </div>
          ),
          width: 'auto',
        },
        ...columns,
      ])
    }
  )

  useEffect(() => {
    const selectedRecipientIds = Object.keys(selectedRowIds)
      .filter(index => selectedRowIds[index])
      .map(index => data[index]._id)
    dispatch(setSelectedRecipients(selectedRecipientIds))
  }, [selectedRowIds])

  return (
    <div className='rounded-md border overflow-x-auto overflow-y-auto max-h-96'>
      <Table {...getTableProps()}>
        <TableHeader>
          {headerGroups.map((headerGroup, index) => (
            <TableRow {...headerGroup.getHeaderGroupProps()} key={index}>
              {headerGroup.headers.map((column, index) => (
                <TableHead {...column.getHeaderProps()} key={index}>
                  <div className='flex gap-2 items-center'>
                    {column.render('Header')}
                    <div>
                      {column.canFilter && column.id === 'status'
                        ? column.render('Filter')
                        : null}
                    </div>
                  </div>
                </TableHead>
              ))}
            </TableRow>
          ))}
          <TableRow>
            <th
              colSpan={visibleColumns.length}
              style={{
                textAlign: 'left',
              }}
            >
              <GlobalFilter
                preGlobalFilteredRows={preGlobalFilteredRows}
                globalFilter={state.globalFilter}
                setGlobalFilter={setGlobalFilter}
              />
            </th>
          </TableRow>
        </TableHeader>
        <TableBody {...getTableBodyProps()}>
          {rows.map((row, i) => {
            prepareRow(row)
            return (
              <TableRow {...row.getRowProps()} key={i}>
                {row.cells.map((cell, index) => {
                  return (
                    <TableCell {...cell.getCellProps()} key={index}>
                      {cell.render('Cell')}
                    </TableCell>
                  )
                })}
              </TableRow>
            )
          })}
        </TableBody>
      </Table>
    </div>
  )
}

type ParamsType = {
  id: string
}

const IndeterminateCheckbox = React.forwardRef(
  ({ indeterminate, ...rest }: any, ref) => {
    const defaultRef = React.useRef()
    const resolvedRef: any = ref || defaultRef

    useEffect(() => {
      resolvedRef.current.indeterminate = indeterminate
    }, [indeterminate, resolvedRef])

    return (
      <>
        <input
          type='checkbox'
          ref={resolvedRef}
          {...rest}
          className='w-4 h-4 text-blue-600 bg-primary rounded focus:bg-primary dark:focus:bg-primary'
        />
      </>
    )
  }
)

type PropsType = {
  errorMessage?: string
  event: IEvent
}

const RecipientTable: FC<PropsType> = ({ event }) => {
  const { t } = useTranslation()

  const { id } = useParams<ParamsType>()
  const { selectedRecipents } = useAppSelector(state => state.userReducer)

  const { data: eventAttendees, loading: eventAttendeesLoading } = useQuery(
    GET_EVENT_ATTENDEES,
    {
      variables: {
        eventId: id,
      },
    }
  )

  const columns = React.useMemo(
    () => [
      {
        Header: t('Email'),
        accessor: 'email',
      },
      {
        Header: t('First Name'),
        Cell: ({ row }) => {
          const firstName = getAttendeeSystemField(
            row.original,
            event,
            SystemFieldType.FirstName,
            'firstName'
          )
          return <b>{firstName}</b>
        },
        accessorFn: getSystemFieldAccessor(
          event,
          SystemFieldType.FirstName,
          'firstName'
        ),
      },
      {
        Header: t('Last Name'),
        Cell: ({ row }) => {
          const lastName = getAttendeeSystemField(
            row.original,
            event,
            SystemFieldType.LastName,
            'lastName'
          )
          return <b>{lastName}</b>
        },
        accessorFn: getSystemFieldAccessor(
          event,
          SystemFieldType.LastName,
          'lastName'
        ),
      },
      {
        Header: t('City'),
        accessor: 'address.city',
      },
      {
        Header: t('Status'),
        accessor: 'status',
        Filter: StatusFilter,
        Cell: ({ row }) => renderStatusLabel(row.original.status),
        filter: multiSelectFilter,
      },
    ],
    []
  )

  function multiSelectFilter(rows, columnIds, filterValue) {
    return filterValue.length === 0
      ? rows
      : rows.filter(row => filterValue.includes(String(row.original.status)))
  }

  if (eventAttendeesLoading) {
    return <Loading />
  }
  const message = t('Please choose at least one recipient')
  return (
    <>
      <div className='flex items-center justify-between'>
        <FormLabel className='text-md font-semibold'>
          {t('Attendees')}
        </FormLabel>
        {!selectedRecipents?.length && (
          <p className='text-sm text-red-600'>
            {message} <strong>*</strong>
          </p>
        )}
      </div>
      {/*<div className='max-h-96 overflow-y-scroll'>*/}
      <AttendeeTable
        columns={columns}
        data={eventAttendees.getEventAttendees.data}
      />
      {/*</div>*/}
    </>
  )
}

export default RecipientTable

const StatusFilter = ({ column }) => {
  const { setFilter, filterValue = [] } = column

  const onCheckboxChange = value => {
    const newFilter = filterValue.includes(value)
      ? filterValue.filter(status => status !== value)
      : [...filterValue, value]
    setFilter(newFilter)
  }

  return (
    <div>
      <Popover>
        <PopoverTrigger asChild>
          <img
            src={FilterIcon}
            width='15px'
            height='15px'
            className='cursor-pointer'
          />
        </PopoverTrigger>
        <PopoverContent className='w-[220px]'>
          {AttendeeStatusLabel().map(option => (
            <div key={option.value} className='flex'>
              <div className='flex items-center space-x-2 m-1'>
                <Checkbox
                  onCheckedChange={() => onCheckboxChange(option.value)}
                  checked={filterValue.includes(option.value)}
                  className='mr-[5px]'
                  id={option.value}
                />
                <label
                  htmlFor={option.value}
                  className='text-sm font-medium leading-none peer-disabled:cursor-not-allowed peer-disabled:opacity-70'
                >
                  {option.label}
                </label>
              </div>
            </div>
          ))}
        </PopoverContent>
      </Popover>
    </div>
  )
}
