import Editor from '@/components/atoms/Editor'
import Loading from '@/components/atoms/Loading'
import { Button } from '@/components/ui/button'
import {
  Form,
  FormControl,
  FormField,
  FormItem,
  FormLabel,
  FormMessage,
} from '@/components/ui/form'
import { Input } from '@/components/ui/input'
import { Label } from '@/components/ui/label'
import { RadioGroup, RadioGroupItem } from '@/components/ui/radio-group'
import { Switch } from '@/components/ui/switch'
import { toast } from '@/components/ui/use-toast'
import { Currency } from '@/constants/currency'
import { EventType } from '@/constants/events'
import { LANGUAGE } from '@/constants/language'
import { MESSAGE } from '@/constants/message'
import { CURRENCY_REGEX } from '@/constants/regex'
import { DUPLICATE_EVENT } from '@/graphql/mutations'
import {
  defaultDateTimeFormat,
  getListCountriesByLanguage,
} from '@/utils/helpers'
import { useClickOutside, useOrganizationIdVar } from '@/utils/hooks'
import { useMutation } from '@apollo/client'
import { zodResolver } from '@hookform/resolvers/zod'
import CustomDatePicker from 'components/atoms/CustomDatePicker'
import CustomSelect from 'components/atoms/CustomSelect'
import { TIME_INTERVAL } from 'constants/time'
import { FC, useEffect, useRef, useState } from 'react'
import { ColorPicker, useColor } from 'react-color-palette'
import { useForm } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { useHistory, useParams } from 'react-router'
import * as z from 'zod'
import { getEventInput } from '../EventDetail/mixins'

type CountryOption = {
  code: string
  name: string
}

type ParamTypes = {
  id: string
}

const languageList = Object.entries(LANGUAGE).map(([key]) => ({
  value: key,
  label: key,
}))

const DuplicateEventPage: FC = () => {
  const { t } = useTranslation()
  const appLanguage = localStorage.getItem('i18nextLng')
  const { id } = useParams<ParamTypes>()
  const history = useHistory()

  const { organizationId } = useOrganizationIdVar()

  const { data, loading } = getEventInput(id)
  const event = data?.event

  const [isClassicEvent, setIsClassicEvent] = useState(true)
  const [isBookableEvent, setIsBookableEvent] = useState(false)
  const countryList = getListCountriesByLanguage(appLanguage).map(
    (country: CountryOption) => ({
      value: country.name,
      label: country.name,
    })
  )

  const [toggleColorPicker, setToggleColorPicker] = useState(false)
  const colorPickerRef = useRef(null)
  const [color, setColor] = useColor(
    'hex',
    event?.eventSetting?.primaryColor || '#2C506C'
  )
  const [displayColor, setDisplayColor] = useState('#2C506C')

  useClickOutside(colorPickerRef, () => {
    setToggleColorPicker(false)
  })

  const formSchema = z
    .object({
      title: z.string().min(1, { message: t(MESSAGE.REQUIRED_FIELD) }),
      type: z.string(),
      mainLanguage: z.string(),
      hotelName: z.union([z.string(), z.null()]),
      address: z.object({
        city: isClassicEvent
          ? z.string().min(1, { message: t(MESSAGE.REQUIRED_FIELD) })
          : z.string(),
        country: isClassicEvent
          ? z.string().min(1, { message: t(MESSAGE.REQUIRED_FIELD) })
          : z.string(),
        zipCode: isClassicEvent
          ? z.string().min(1, { message: t(MESSAGE.REQUIRED_FIELD) })
          : z.string(),
        street: isClassicEvent
          ? z.string().min(1, { message: t(MESSAGE.REQUIRED_FIELD) })
          : z.string(),
      }),
      description: z.string().nullable(),
      startEvent: z.union([z.string(), z.date()]),
      endEvent: z.union([z.string(), z.date()]),
      isBookable: z.boolean(),
      sendQrCode: z.boolean(),
      isBusinessEvent: z.boolean(),
      hasWebcastIntegration: z.boolean(),
      sendByOrganizationEmail: z.boolean(),
      eventSetting: z.object({
        cancellation: z.union([z.boolean(), z.null()]),
        primaryColor: z.string(),
      }),
      shouldCreateAttendantUser: z.boolean(),
      price: isBookableEvent
        ? z
            .string()
            .min(1, { message: t(MESSAGE.REQUIRED_FIELD) })
            .regex(
              CURRENCY_REGEX,
              t('{{name}} only accepts numbers', { name: t('Price') })
            )
        : z.string(),
      currency: z.string(),
      capacity: z.union([
        z
          .string()
          .regex(
            CURRENCY_REGEX,
            t('{{name}} only accepts numbers', { name: t('Capacity') })
          ),
        z.null(),
      ]),
      hotelBookingUrl: z.string(),
      copyFeedback: z.boolean().optional(),
      slug: z.string().min(1, { message: t(MESSAGE.REQUIRED_FIELD) }),
    })
    .refine(data => data.endEvent >= data.startEvent, {
      message: t('End time must be greater than start time'),
      path: ['endEvent'],
    })

  useEffect(() => {
    if (event) {
      const {
        title,
        type,
        mainLanguage,
        hotelName,
        address,
        description,
        startEvent,
        endEvent,
        isBookable,
        sendQrCode,
        isBusinessEvent,
        webcastEventId,
        sendByOrganizationEmail,
        eventSetting: { cancellation, primaryColor },
        shouldCreateAttendantUser,
        price,
        currency,
        capacity,
        hotelBookingUrl,
        agendaUrl,
        slug,
      } = event
      form.reset({
        title: `[COPY] - ${title}`,
        type,
        mainLanguage,
        hotelName: hotelName || '',
        address,
        description,
        startEvent: new Date(startEvent),
        endEvent: new Date(endEvent),
        isBookable: isBookable || false,
        sendQrCode: sendQrCode || false,
        isBusinessEvent: isBusinessEvent || false,
        hasWebcastIntegration: Boolean(webcastEventId),
        sendByOrganizationEmail: sendByOrganizationEmail || false,
        eventSetting: { cancellation: cancellation || false, primaryColor },
        shouldCreateAttendantUser: shouldCreateAttendantUser || false,
        price: price ? String(price) : '',
        currency,
        capacity: capacity ? String(capacity) : '',
        hotelBookingUrl,
        agendaUrl,
        slug: `${slug}-copy`,
      })
      setIsClassicEvent(type === EventType.CLASSIC)
      setIsBookableEvent(isBookable)
      if (event?.eventSetting?.primaryColor) {
        setDisplayColor(event?.eventSetting?.primaryColor)
      }
    }
  }, [event, setColor])

  const form = useForm({
    resolver: zodResolver(formSchema),
    defaultValues: {
      title: '',
      type: EventType.CLASSIC,
      mainLanguage: '',
      hotelName: '',
      startEvent: new Date(),
      endEvent: new Date(),
      address: {
        street: '',
        city: '',
        zipCode: '',
        country: '',
      },
      description: '',
      isBookable: false,
      sendQrCode: false,
      isBusinessEvent: false,
      hasWebcastIntegration: false,
      sendByOrganizationEmail: false,
      eventSetting: {
        cancellation: false,
        primaryColor: '',
      },
      shouldCreateAttendantUser: false,
      price: '',
      currency: '',
      capacity: '',
      hotelBookingUrl: '',
      agendaUrl: '',
      copyFeedback: false,
      slug: '',
    },
    mode: 'onChange',
  })

  // TODO: for debugging
  // const {
  //   formState: { errors },
  // } = form
  // console.log('errors', errors)

  function onSubmit(values: z.infer<typeof formSchema>) {
    mutateDuplicateEvent({
      variables: {
        eventId: event._id,
        organizationId,
        duplicateEventInput: {
          ...values,
          capacity: Number(values.capacity),
          price: isBookableEvent ? Number(values.price) : 0,
          eventSetting: { ...values.eventSetting, primaryColor: displayColor },
        },
      },
    })
  }

  const [mutateDuplicateEvent] = useMutation(DUPLICATE_EVENT, {
    onCompleted({ duplicateEvent }) {
      if (duplicateEvent) {
        toast({
          description: t('Duplicate event success!'),
        })
        history.push('/')
      }
    },
    onError: err => {
      err.graphQLErrors.map(({ message }) => {
        toast({
          variant: 'destructive',
          description: t(message),
        })
      })
    },
  })

  if (loading) return <Loading />

  const SwitchItem = ({ control, name, title, disabled = false }) => {
    return (
      <FormField
        control={control}
        name={name}
        render={({ field }) => {
          return (
            <FormItem>
              <div className='flex items-center space-x-2 justify-start'>
                <Switch
                  id={name}
                  checked={field.value}
                  onCheckedChange={value => form.setValue(name, value)}
                  disabled={disabled}
                />
                <Label htmlFor={name}>{title}</Label>
              </div>
            </FormItem>
          )
        }}
      />
    )
  }

  const onChangeEventDescription = (value: string) => {
    form.setValue('description', value)
  }

  if (!data) return <Loading />

  return (
    <div className='space-y-6 p-10'>
      <Form {...form}>
        <form onSubmit={form.handleSubmit(onSubmit)} className='space-y-8'>
          <div className='flex gap-[80px]'>
            <div className='flex-1'>
              <div className='flex gap-[20px] flex-col'>
                <FormField
                  control={form.control}
                  name='title'
                  render={({ field }) => (
                    <FormItem>
                      <FormLabel>
                        {t('Event Title')} <span className='text-red'>*</span>
                      </FormLabel>
                      <FormControl>
                        <Input {...field} />
                      </FormControl>
                      <FormMessage />
                    </FormItem>
                  )}
                />
                <FormField
                  control={form.control}
                  name='slug'
                  render={({ field }) => (
                    <FormItem>
                      <FormLabel>
                        {t('Event Slug')} <span className='text-red'>*</span>
                      </FormLabel>
                      <FormControl>
                        <Input {...field} />
                      </FormControl>
                      <FormMessage />
                    </FormItem>
                  )}
                />
                <div className='flex justify-between'>
                  <FormField
                    control={form.control}
                    name='type'
                    render={({ field }) => {
                      return (
                        <FormItem>
                          <FormLabel>{t('Event Type')}</FormLabel>
                          <FormControl>
                            <RadioGroup
                              onValueChange={value => {
                                field.onChange(value)
                                setIsClassicEvent(value === EventType.CLASSIC)
                              }}
                              value={field.value}
                            >
                              <div className='flex items-center space-x-2'>
                                <RadioGroupItem
                                  value={EventType.CLASSIC}
                                  id={EventType.CLASSIC}
                                />
                                <Label htmlFor={EventType.CLASSIC}>
                                  {t('Classic')}
                                </Label>
                              </div>
                              <div className='flex items-center space-x-2'>
                                <RadioGroupItem
                                  value={EventType.DIGITAL}
                                  id={EventType.DIGITAL}
                                />
                                <Label htmlFor={EventType.DIGITAL}>
                                  {t('Digital')}
                                </Label>
                              </div>
                            </RadioGroup>
                          </FormControl>
                          <FormMessage />
                        </FormItem>
                      )
                    }}
                  />
                  <FormField
                    control={form.control}
                    name='mainLanguage'
                    render={({ field }) => {
                      return (
                        <FormItem>
                          <FormLabel>{t('Choose main language')}</FormLabel>
                          <FormControl>
                            <CustomSelect
                              options={languageList}
                              placeholder={t('Select country')}
                              onChange={option =>
                                option &&
                                form.setValue('mainLanguage', option.value)
                              }
                              value={{
                                value: field.value,
                                label: field.value,
                              }}
                            />
                          </FormControl>
                          <FormMessage />
                        </FormItem>
                      )
                    }}
                  />
                </div>

                <div className='flex justify-between'>
                  <FormField
                    control={form.control}
                    name='startEvent'
                    render={({ field }) => {
                      return (
                        <FormItem>
                          <FormLabel>
                            {t('Start of event')}{' '}
                            <span className='text-red-600'>*</span>
                          </FormLabel>
                          <FormControl>
                            <CustomDatePicker
                              name='startEvent'
                              value={new Date(field.value)}
                              timeIntervals={TIME_INTERVAL}
                              dateFormat={defaultDateTimeFormat}
                              showTimeSelect
                              onChange={date =>
                                form.setValue('startEvent', date.toISOString())
                              }
                              minDate={new Date()}
                            />
                          </FormControl>
                          <FormMessage />
                        </FormItem>
                      )
                    }}
                  />

                  <FormField
                    control={form.control}
                    name='endEvent'
                    render={({ field }) => {
                      return (
                        <FormItem>
                          <FormLabel>
                            {t('End of event')}{' '}
                            <span className='text-red-600'>*</span>
                          </FormLabel>
                          <FormControl>
                            <CustomDatePicker
                              name='endEvent'
                              value={new Date(field.value)}
                              timeIntervals={TIME_INTERVAL}
                              dateFormat={defaultDateTimeFormat}
                              showTimeSelect
                              onChange={date =>
                                form.setValue('endEvent', date.toISOString())
                              }
                              minDate={new Date(event.startEvent)}
                            />
                          </FormControl>
                          <FormMessage />
                        </FormItem>
                      )
                    }}
                  />
                </div>
                {isClassicEvent && (
                  <>
                    <FormField
                      control={form.control}
                      name='hotelName'
                      render={({ field }) => (
                        <FormItem>
                          <FormLabel>{t('Hotel name')}</FormLabel>
                          <FormControl>
                            <Input {...field} />
                          </FormControl>
                          <FormMessage />
                        </FormItem>
                      )}
                    />

                    <FormField
                      control={form.control}
                      name='address.country'
                      render={({ field }) => {
                        return (
                          <FormItem>
                            <FormLabel>
                              {t('Country')}{' '}
                              {isClassicEvent && (
                                <span className='text-red-600'>*</span>
                              )}
                            </FormLabel>
                            <FormControl>
                              <CustomSelect
                                options={countryList}
                                placeholder={t('Select country')}
                                onChange={option =>
                                  option &&
                                  form.setValue('address.country', option.value)
                                }
                                value={{
                                  value: field.value,
                                  label: field.value,
                                }}
                                isSearchable
                              />
                            </FormControl>
                            <FormMessage />
                          </FormItem>
                        )
                      }}
                    />
                    <FormField
                      control={form.control}
                      name='address.street'
                      render={({ field }) => (
                        <FormItem>
                          <FormLabel>
                            {t('Street')}{' '}
                            {isClassicEvent && (
                              <span className='text-red-600'>*</span>
                            )}
                          </FormLabel>
                          <FormControl>
                            <Input {...field} />
                          </FormControl>
                          <FormMessage />
                        </FormItem>
                      )}
                    />

                    <div className='flex gap-[20px] justify-between'>
                      <FormField
                        control={form.control}
                        name='address.city'
                        render={({ field }) => (
                          <FormItem>
                            <FormLabel>
                              {t('City')}{' '}
                              {isClassicEvent && (
                                <span className='text-red-600'>*</span>
                              )}
                            </FormLabel>
                            <FormControl>
                              <Input {...field} />
                            </FormControl>
                            <FormMessage />
                          </FormItem>
                        )}
                      />
                      <FormField
                        control={form.control}
                        name='address.zipCode'
                        render={({ field }) => (
                          <FormItem>
                            <FormLabel>
                              {t('Zip')}{' '}
                              {isClassicEvent && (
                                <span className='text-red-600'>*</span>
                              )}
                            </FormLabel>
                            <FormControl>
                              <Input {...field} />
                            </FormControl>
                            <FormMessage />
                          </FormItem>
                        )}
                      />
                    </div>
                  </>
                )}

                <FormField
                  name='description'
                  render={({ field }) => {
                    return (
                      <FormItem>
                        <FormLabel>{t('Event description')}</FormLabel>
                        <Editor
                          className='content-editor'
                          value={event?.description}
                          isMention={false}
                          onChange={onChangeEventDescription}
                        />
                        <FormMessage />
                      </FormItem>
                    )
                  }}
                />
              </div>
            </div>

            <div className='flex-1'>
              <div className='flex flex-col gap-[20px]'>
                <FormField
                  control={form.control}
                  name='isBookable'
                  render={({ field }) => {
                    return (
                      <FormItem>
                        <div className='flex items-center space-x-2 justify-start'>
                          <Switch
                            id='isBookable'
                            checked={field.value}
                            onCheckedChange={value => {
                              form.setValue('isBookable', value)
                              setIsBookableEvent(value)
                            }}
                          />
                          <Label htmlFor='isBookable'>
                            {t('Booking with Payment?')}
                          </Label>
                        </div>
                      </FormItem>
                    )
                  }}
                />

                {isBookableEvent && (
                  <div className='flex justify-between'>
                    <FormField
                      control={form.control}
                      name='price'
                      render={({ field }) => (
                        <FormItem>
                          <FormLabel>
                            {t('Price per person')}{' '}
                            {isBookableEvent && (
                              <span className='text-red-600'>*</span>
                            )}
                          </FormLabel>
                          <FormControl>
                            <Input {...field} />
                          </FormControl>
                          <FormMessage />
                        </FormItem>
                      )}
                    />

                    <FormField
                      control={form.control}
                      name='currency'
                      render={({ field }) => {
                        return (
                          <FormItem>
                            <FormLabel>{t('Choose main language')}</FormLabel>
                            <FormControl>
                              <CustomSelect
                                options={Currency}
                                placeholder={t('Select')}
                                defaultValue={Currency[0]}
                                onChange={option =>
                                  option &&
                                  form.setValue('currency', option.value)
                                }
                                value={{
                                  value: field.value,
                                  label: field.value,
                                }}
                              />
                            </FormControl>
                            <FormMessage />
                          </FormItem>
                        )
                      }}
                    />
                  </div>
                )}

                <FormField
                  control={form.control}
                  name='eventSetting.primaryColor'
                  render={({ field }) => {
                    return (
                      <FormItem>
                        <FormLabel>{t('Color')}</FormLabel>
                        <div
                          style={{
                            background: `${displayColor}`,
                          }}
                          className='w-[40px] h-[40px] top-[55px] z-3'
                          onClick={() =>
                            setToggleColorPicker(!toggleColorPicker)
                          }
                        />
                        {toggleColorPicker && (
                          <div ref={colorPickerRef}>
                            <ColorPicker
                              width={300}
                              height={120}
                              color={color}
                              onChange={(color: any) => {
                                setColor(color)
                                setDisplayColor(color.hex)
                              }}
                              hideHSV
                              hideRGB
                              dark
                            />
                          </div>
                        )}
                      </FormItem>
                    )
                  }}
                />
                <FormField
                  control={form.control}
                  name='capacity'
                  render={({ field }) => (
                    <FormItem>
                      <FormLabel>{t('Capacity')} </FormLabel>
                      <FormControl>
                        <Input {...field} />
                      </FormControl>
                      <FormMessage />
                    </FormItem>
                  )}
                />
                <FormField
                  control={form.control}
                  name='hotelBookingUrl'
                  render={({ field }) => (
                    <FormItem>
                      <FormLabel>
                        {t('event_admin.hotel_booking_url')}{' '}
                      </FormLabel>
                      <FormControl>
                        <Input {...field} />
                      </FormControl>
                      <FormMessage />
                    </FormItem>
                  )}
                />
                <FormField
                  control={form.control}
                  name='agendaUrl'
                  render={({ field }) => (
                    <FormItem>
                      <FormLabel>{t('event_admin.agenda_url')} </FormLabel>
                      <FormControl>
                        <Input {...field} />
                      </FormControl>
                      <FormMessage />
                    </FormItem>
                  )}
                />

                <SwitchItem
                  control={form.control}
                  name='sendQrCode'
                  title={t('Send QR-Code after register?')}
                />
                {/*<SwitchItem*/}
                {/*  control={form.control}*/}
                {/*  name='isBusinessEvent'*/}
                {/*  title={t('Is business event?')}*/}
                {/*/>*/}
                <SwitchItem
                  control={form.control}
                  name='hasWebcastIntegration'
                  title={t('with Webcast integration?')}
                  disabled={event?.webcastEventId}
                />
                {/*<SwitchItem*/}
                {/*  control={form.control}*/}
                {/*  name='sendByOrganizationEmail'*/}
                {/*  title={t('Send confirmation by event organizer email?')}*/}
                {/*/>*/}
                <SwitchItem
                  control={form.control}
                  name='eventSetting.cancellation'
                  title={t('Enable cancellation?')}
                />
                <SwitchItem
                  control={form.control}
                  name='shouldCreateAttendantUser'
                  title={t('Create attendee account')}
                />

                <SwitchItem
                  control={form.control}
                  name='copyFeedback'
                  title={t('duplicate_event.copy_feedback_questions')}
                />

                <Button type='submit' className='mt-[20px]'>
                  {t('Duplicate Event')}
                </Button>
              </div>
            </div>
          </div>
        </form>
      </Form>
    </div>
  )
}

export default DuplicateEventPage
