import { Button } from '@/components/ui/button'
import {
  Dialog,
  DialogContent,
  DialogFooter,
  DialogHeader,
  DialogTitle,
} from '@/components/ui/dialog'
import { Checkbox } from '@/components/ui/checkbox'
import { FC, useEffect, useState } from 'react'
import { Icons } from '@/components/ui/icon'
import { t } from 'i18next'
import { useTranslation } from 'react-i18next'
import { ICustomSelection } from '@/models'

const columns = [
  {
    key: 'Email',
    label: t('export_attendee_modal.fields.email'),
  },
  {
    key: 'FirstName',
    label: t('export_attendee_modal.fields.firstName'),
  },
  {
    key: 'LastName',
    label: t('export_attendee_modal.fields.lastName'),
  },
  {
    key: 'Salutation',
    label: t('export_attendee_modal.fields.salutation'),
  },
  {
    key: 'AcademicTitle',
    label: t('export_attendee_modal.fields.academic'),
  },
  {
    key: 'JobTitle',
    label: t('export_attendee_modal.fields.jobTitle'),
  },
  {
    key: 'Company',
    label: t('export_attendee_modal.fields.company'),
  },
  {
    key: 'CustomFieldRegistration',
    label: t('export_attendee_modal.fields.registration'),
  },
  {
    key: 'Address',
    label: t('export_attendee_modal.fields.address'),
  },
  {
    key: 'WorkPhone',
    label: t('export_attendee_modal.fields.workPhone'),
  },
  {
    key: 'MobilePhone',
    label: t('export_attendee_modal.fields.mobilePhone'),
  },
  {
    key: 'Fax',
    label: t('export_attendee_modal.fields.fax'),
  },
  {
    key: 'Status',
    label: t('export_attendee_modal.fields.status'),
  },
  {
    key: 'QRCode',
    label: t('export_attendee_modal.fields.qr'),
  },
  {
    key: 'InvitationStatus',
    label: t('export_attendee_modal.fields.invitationStatus'),
  },
]

type ExportAttendeeModalProps = {
  open: boolean
  onClose: any
  onExport: any
  loading: boolean
  customSelections: ICustomSelection[]
}
const ExportAttendeeModal: FC<ExportAttendeeModalProps> = ({
  open,
  onClose,
  onExport,
  loading,
  customSelections,
}) => {
  const { t } = useTranslation()
  const [isSelectedAll, setIsSelectedAll] = useState(true)

  const systemFieldCustomSelections = customSelections.filter(
    selection => selection.systemField
  )
  const nonSystemFieldCustomSelections = customSelections.filter(
    selection => !selection.systemField
  )

  const processedColumns = columns.map(column => {
    const matchingCustom = systemFieldCustomSelections.find(
      cs => cs.systemField?.toLowerCase() === column.key.toLowerCase()
    )
    return {
      ...column,
      label: matchingCustom
        ? `${matchingCustom.label} (${column.label})`
        : column.label,
    }
  })

  const allColumns = [
    ...processedColumns,
    ...nonSystemFieldCustomSelections.map(selection => ({
      key: `Custom_${selection.id}`,
      label: selection.label,
    })),
  ]

  const selectedColumnsObj = allColumns.reduce((acc, column) => {
    acc[column.key] = isSelectedAll
    return acc
  }, {})

  const [selectedColumns, setSelectedColumns] = useState(selectedColumnsObj)

  useEffect(() => {
    const selectedColumnsObj = allColumns.reduce((acc, column) => {
      acc[column.key] = isSelectedAll
      return acc
    }, {})
    setSelectedColumns(selectedColumnsObj)
  }, [isSelectedAll])

  return (
    <Dialog open={open} onOpenChange={onClose}>
      <DialogContent className='sm:max-w-[425px]'>
        <DialogHeader>
          <DialogTitle>{t('export_attendee_modal.title')}</DialogTitle>
        </DialogHeader>
        <div className='flex items-center space-x-2 -mb-2'>
          <Checkbox
            id='all'
            checked={isSelectedAll}
            onCheckedChange={() => setIsSelectedAll(!isSelectedAll)}
          />
          <label
            htmlFor='all'
            className='text-sm leading-none peer-disabled:cursor-not-allowed peer-disabled:opacity-70 bg-muted py-2 px-2 w-[150px] font-bold'
          >
            {t('export_attendee_modal.fields.all')}
          </label>
        </div>
        <div className='py-4 grid grid-cols-2 gap-4'>
          {allColumns.map(column => {
            return (
              <div className='flex items-center space-x-2' key={column.key}>
                <Checkbox
                  id={column.key}
                  checked={isSelectedAll || selectedColumns[column.key]}
                  onCheckedChange={() => {
                    setSelectedColumns({
                      ...selectedColumns,
                      [column.key]: !selectedColumns[column.key],
                    })
                  }}
                />
                <label
                  htmlFor={column.key}
                  className='text-sm font-medium leading-none peer-disabled:cursor-not-allowed peer-disabled:opacity-70 bg-muted py-2 px-2 w-[150px]'
                >
                  {column.label}
                </label>
              </div>
            )
          })}
        </div>
        <DialogFooter>
          <Button variant='outline' onClick={onClose} disabled={loading}>
            {t('export_attendee_modal.cancel_btn')}
          </Button>
          <Button onClick={() => onExport(selectedColumns)} disabled={loading}>
            {loading && <Icons.spinner className='mr-2 h-4 w-4 animate-spin' />}
            {t('export_attendee_modal.export_btn')}
          </Button>
        </DialogFooter>
      </DialogContent>
    </Dialog>
  )
}

export default ExportAttendeeModal
