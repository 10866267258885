import { FC } from 'react'
import { useTranslation } from 'react-i18next'
import { Button } from '@/components/ui/button'
import { UploadIcon, FileIcon, XIcon } from 'lucide-react'
import { RadioGroup, RadioGroupItem } from '@/components/ui/radio-group'

interface UploadStepProps {
  handleOnChange: (event: React.ChangeEvent<HTMLInputElement>) => void
  handleOnClick: (event: React.MouseEvent<HTMLInputElement>) => void
  onNext: () => void
  isUploading: boolean
  uploadProgress: number
  fileName?: string
  onCancel: () => void
  selectedCharset: string
  onCharsetChange: (charset: string) => void
}

export const UploadStep: FC<UploadStepProps> = ({
  handleOnChange,
  handleOnClick,
  onNext,
  isUploading,
  uploadProgress,
  fileName,
  onCancel,
  selectedCharset,
  onCharsetChange,
}) => {
  const { t } = useTranslation()

  return (
    <div className='space-y-4'>
      <div className='space-y-2'>
        <h2 className='text-xl font-semibold'>
          {t('import_attendees.upload_step.title')}
        </h2>
        <p className='text-muted-foreground'>
          {t('import_attendees.upload_step.description')}
        </p>
      </div>

      {!isUploading ? (
        <div className='space-y-4'>
          <RadioGroup
            value={selectedCharset}
            onValueChange={onCharsetChange}
            className='flex items-center space-x-4 mb-2'
          >
            <div className='flex items-center space-x-2'>
              <RadioGroupItem value='UTF-8' id='utf8' />
              <label htmlFor='utf8'>UTF-8</label>
            </div>
            <div className='flex items-center space-x-2'>
              <RadioGroupItem value='ASCII' id='ascii' />
              <label htmlFor='ascii'>ASCII</label>
            </div>
          </RadioGroup>

          <div className='border-2 border-dashed border-gray-200 rounded-lg p-8 text-center'>
            <div className='flex flex-col items-center space-y-4'>
              <input
                type='file'
                id='csvFileInput'
                accept='.csv'
                onChange={handleOnChange}
                onClick={handleOnClick}
                className='hidden'
              />
              <label
                htmlFor='csvFileInput'
                className='cursor-pointer flex flex-col items-center space-y-2'
              >
                <div className='p-3 rounded-full bg-gray-100'>
                  <UploadIcon className='w-6 h-6' />
                </div>
                <span>{t('import_attendees.upload_step.drop_file')}</span>
                <span className='text-sm text-muted-foreground'>
                  {t('import_attendees.upload_step.best_number_of_rows')}
                </span>
              </label>
            </div>
          </div>
        </div>
      ) : (
        <div className='border rounded-lg p-4'>
          <div className='flex items-center space-x-3 mb-2'>
            <div className='p-2 bg-gray-100 rounded'>
              <FileIcon className='w-4 h-4' />
            </div>
            <div className='flex-1'>
              <div className='flex justify-between'>
                <span className='text-sm font-medium'>{fileName}</span>
                <span className='text-sm text-muted-foreground'>
                  {uploadProgress}%
                </span>
              </div>
              <div className='w-full bg-gray-200 rounded-full h-2 mt-1'>
                <div
                  className='bg-primary h-2 rounded-full transition-all duration-300'
                  style={{ width: `${uploadProgress}%` }}
                />
              </div>
            </div>
            <Button
              variant='ghost'
              size='icon'
              className='h-8 w-8'
              onClick={onCancel}
            >
              <XIcon className='h-4 w-4' />
            </Button>
          </div>
          <p className='text-sm text-muted-foreground'>
            {uploadProgress < 100
              ? t('import_attendees.upload_step.uploading')
              : t('import_attendees.upload_step.upload_complete')}
          </p>
        </div>
      )}

      <div className='flex items-center space-x-2 text-sm'>
        <span>{t('import_attendees.upload_step.learn_more_about_importing_attendees')}</span>
        <Button
          variant='link'
          className='p-0 h-auto'
          onClick={() => {
            window.open(
              'https://conference-direct.s3-eu-central-1.ionoscloud.com/sample.csv',
              '_blank'
            )
          }}
        >
          {t('import_attendees.upload_step.download_sample_csv_file')}
        </Button>
      </div>

      <div className='flex justify-end'>
        <Button onClick={onNext}>{t('import_attendees.next')}</Button>
      </div>
    </div>
  )
}
