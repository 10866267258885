import { useQuery } from '@apollo/client'
import { i18n } from 'config'
import { LANGUAGE, SUPPORT_LANGUAGE } from 'constants/language'
import { PUBLIC_GET_EVENT_BY_ID } from 'graphql/queries'
import _ from 'lodash'
import { IEvent } from 'models'
import { FC, useEffect, useState } from 'react'
// import { withNamespaces } from 'react-i18next'
import { useLocation, useParams } from 'utils/adapters'
import { setEventLang, setSelectedEvent } from 'store/Events'
import { useAppDispatch } from 'utils/hooks'

import Loading from 'components/atoms/Loading'
import ClientEventDetail from './ClientEventDetail'
import NoEventFound from '../NotFound'

const ClientEventDetailContainer: FC = () => {
  const { id } = useParams<{ id: string }>()
  const location = useLocation()
  const dispatch = useAppDispatch()

  const [isError, setIsError] = useState<boolean>(false)
  const [event, setEvent] = useState<IEvent | any>({})

  const [language, setLanguage] = useState('')

  const getEventLanguage = (language: string | undefined) => {
    const idx = SUPPORT_LANGUAGE.findIndex(
      item => item.name.toLowerCase() === language?.toLowerCase()
    )
    return idx > -1 ? SUPPORT_LANGUAGE[idx]?.value : LANGUAGE.ENGLISH
  }

  const { data, loading } = useQuery(PUBLIC_GET_EVENT_BY_ID, {
    onCompleted: () => {
      if (data) {
        setEvent(data.event)
        dispatch(setSelectedEvent(data.event))
        // change default language
        const lang = getEventLanguage(data?.event?.mainLanguage)
        i18n.changeLanguage(lang)
        dispatch(setEventLang(lang))
        setLanguage(lang)
      }
    },
    onError: err => {
      err.graphQLErrors.map((error: any) => error?.statusCode === 404)
      setIsError(true)
    },
    variables: {
      ...(id?.match(
        /^[0-9a-f]{8}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{12}$/i
      )
        ? { id }
        : { slug: id }),
    },
    fetchPolicy: 'network-only',
  })

  useEffect(() => {
    const clientEventDetailUrl = location.pathname
    localStorage.setItem(
      'clientEventDetailUrl',
      JSON.stringify({ clientEventPath: clientEventDetailUrl })
    )
  }, [location])

  if (isError) {
    return <NoEventFound />
  }

  if (loading || _.isEmpty(event) || !language) {
    return <Loading />
  }

  return <ClientEventDetail event={event} />
}

export default ClientEventDetailContainer
