export enum EventType {
  CLASSIC = 'CLASSIC',
  DIGITAL = 'DIGITAL',
}

export enum EEventType {
  CLASSIC = 'classic',
  DIGITAL = 'digital',
}

export enum EventLayoutType {
  CLASSIC = 'CLASSIC',
  TWO_SIDED = 'TWO_SIDED',
}
