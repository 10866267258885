import { ActionRefundCancelBookingStatus } from '@/utils/helpers'
import { ICustomSelection } from './ICustomSelection'
import { EventLayoutType } from '@/constants/events'

export type IEvent = {
  _id: string
  userId?: string
  title?: string
  description?: string
  address?: Address
  startEvent?: string
  endEvent?: string
  price?: number
  currency?: Currency
  attendeesCount: number
  isExpired?: boolean
  hotelName?: string
  hotelBookingUrl?: string
  agendaURL?: string
  attendees: Attendee[]
  customFields?: EventCustomValue[]
  coordinates?: Coordinates
  eventSetting?: EventSetting
  slug?: string
  mainLanguage?: string
  isBookable?: boolean
  isArchived?: boolean
  emailContent?: string
  capacity?: number
  emailAttachments?: emailAttachments[]
  isBusinessEvent?: boolean
  timezone: string | any
  type: string
  attendeeStatus?: string
  customSelections?: ICustomSelection[]
  toggleDefaultFields: {
    firstName: {
      enabled: boolean
      required: boolean
    }
    lastName: {
      enabled: boolean
      required: boolean
    }
    salutation: {
      enabled
      required
    }
    professional: {
      enabled: boolean
      required: boolean
    }
    jobTitle: {
      enabled: boolean
      required: boolean
    }
    company: {
      enabled: boolean
      required: boolean
    }
    address: {
      street: {
        enabled: boolean
        required: boolean
      }
      zipCode: {
        enabled: boolean
        required: boolean
      }
      city: {
        enabled: boolean
        required: boolean
      }
      country: {
        enabled: boolean
        required: boolean
      }
    }
    workPhone: {
      enabled: boolean
      required: boolean
    }
    mobilePhone: {
      enabled: boolean
      required: boolean
    }
    fax: {
      enabled: boolean
      required: boolean
    }
  }
  allQuestions: QuestionField[][]
}

export type QRAttendeeDetail = {
  attendee: Attendee
  event: IEvent
}

type emailAttachments = {
  encoding: string
  uri: string
  filename: string
  mimetype: string
}

type EventSetting = {
  primaryColor: string
  fontColor?: string
  logo: string
  eventPicture: string
  backgroundPicture: string
  cancellation: boolean
  backgroundTransparent?: string
  logoSize?: string
  isLockedRegister?: boolean
  isCalendarEntryEnabled?: boolean
  bgColor?: string
  bgColorOpacity?: string
  isShowPrice?: boolean
  layoutType?: EventLayoutType
}

type Coordinates = {
  lat: number
  lng: number
  displayName?: string
}

export type Cancellation = {
  isCancelled: boolean
  cancellationReason: string
  cancellationDate: Date
  cancellationFee: number
  refundFee: number
  action?: ActionRefundCancelBookingStatus
}

export type Attendee = {
  _id: string
  salutation: string
  professional: string
  firstName: string
  lastName: string
  jobTitle: string
  company: string
  address: Address
  email: string
  workPhone: string
  mobilePhone: string
  fax: string
  status: string
  registration: Registration
  webcastParticipantId: string
  webcastUrlJoinLinks: []
  cancellation?: Cancellation
  totalPrice?: number
  invitationStatus: string
  additionalFields?: AdditionalFields
  customSelection: any
}

export type AdditionalFields = {
  [key: string]: string
}

export type Address = {
  street: string
  zipCode: string
  city: string
  country: string
}

export type Registration = {
  checked: boolean
  registrationCode: string
  registrationCodes: string[]
  registrationDate: string
  customValues: EventCustomValue[]
}

export type EventCustomValue = {
  eventCustomField: CustomField
  value: CustomFieldOption[]
}

export type EventCustomFieldValue = {
  eventCustomField: string
  value: CustomFieldOption[]
}

export type CustomField = {
  _id: string
  isRequired: boolean
  title: string
  options: CustomFieldOption[]
  type: string
}

export enum CustomFieldType {
  CHECK_BOX = 'CHECK_BOX',
  DATE_TIME = 'DATE_TIME',
  RADIO_BUTTON = 'RADIO_BUTTON',
  SELECT_BOX = 'SELECT_BOX',
  TEXT = 'TEXT',
}

export type CustomFieldOption = {
  name: string
  fee: number
}

export enum Currency {
  EUR,
  USD,
}

export type IPlaceHolders = {
  // _id: string
  // createdAt: Date
  name: string
}

export type FileType = {
  uri: string
  filename: string
  mimetype: string
}

export type ImportAttendeeType = {
  id: number
  firstName: string
  lastName: string
  jobTitle: string
  company: string
  address: Address
  email: string
  workPhone: string
  mobilePhone: string
  fax: string
  salutation: string
  professional: string
}

export type ImportAttendeeCSVDataType = {
  id: number
  first_name: string
  last_name: string
  job_title: string
  company: string
  address: Address
  email: string
  work_phone: string
  mobile_phone: string
  fax: string
  salutation: string
  academic_title: string
}

export enum EventAction {
  CREATE,
  UPDATE,
}

export type QuestionField = {
  id: string
  label: string
  type: string
  required: boolean
  options?: CustomFieldOption[]
  validation?: string
  enabled: boolean
  fieldType: string
}
