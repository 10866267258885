import { FC } from 'react'
import { Step } from '../ImportAttendees'
import { useTranslation } from 'react-i18next'

interface StepIndicatorProps {
  currentStep: Step
}

export const StepIndicator: FC<StepIndicatorProps> = ({ currentStep }) => {
  const { t } = useTranslation()
  const steps: { label: string; step: Step }[] = [
    {
      label: t('import_attendees.step_indicator.upload'),
      step: 'upload',
    },
    {
      label: t('import_attendees.step_indicator.map'),
      step: 'map',
    },
    {
      label: t('import_attendees.step_indicator.import'),
      step: 'import',
    },
  ]

  return (
    <div className='flex justify-between items-center max-w-4xl mx-auto py-6'>
      {steps.map((step, index) => (
        <div
          key={step.step}
          className={`flex items-center relative flex-1 ${
            currentStep === step.step ? 'text-primary' : 'text-muted-foreground'
          }`}
        >
          {index < steps.length - 1 && (
            <div className='absolute top-6 -right-2 w-[calc(100%_-_6rem)] h-[2px] z-0'>
              <div className={`w-full h-full rounded-full transition-colors duration-200 ${
                index < steps.findIndex(s => s.step === currentStep)
                  ? 'bg-primary'
                  : 'bg-gray-200'
              }`} />
            </div>
          )}
          
          <div className='flex items-center mx-6 relative z-10 bg-white'>
            <div
              className={`w-12 h-12 rounded-full flex items-center justify-center border-2 font-semibold text-lg transition-all duration-200
              ${
                currentStep === step.step
                  ? 'border-primary bg-primary text-white scale-110 shadow-md'
                  : index < steps.findIndex(s => s.step === currentStep)
                  ? 'border-primary bg-primary/10 text-primary'
                  : 'border-gray-200 bg-white text-gray-400'
              }`}
            >
              {index + 1}
            </div>
            <span className={`mx-4 font-medium text-base transition-colors duration-200 whitespace-nowrap ${
              currentStep === step.step ? 'text-gray-900' : 'text-gray-500'
            }`}>
              {t(step.label)}
            </span>
          </div>
        </div>
      ))}
    </div>
  )
}
