import { FC, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { Button } from '@/components/ui/button'
import Papa from 'papaparse'
import { Check, FileSpreadsheet, Info } from 'lucide-react'
import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from '@/components/ui/select'

interface MapStepProps {
  onNext: () => void
  onBack: () => void
  file: File | null
  onColumnMappingChange: (mapping: Record<string, string>) => void
  columnMapping: Record<string, string>
  attendeeProperties: Record<string, string>[]
}

function findBestMatch(columnName: string, properties: any[]): string | null {
  const normalizedColumn = columnName.toLowerCase().replace(/[^a-z0-9]/g, '')

  // Use properties directly as it's already an array
  for (const prop of properties) {
    const normalizedProp = prop.label.toLowerCase().replace(/[^a-z0-9]/g, '')
    if (normalizedColumn === normalizedProp) {
      return prop.key
    }
  }

  // Try contains matches
  for (const prop of properties) {
    const normalizedProp = prop.label.toLowerCase().replace(/[^a-z0-9]/g, '')
    if (
      normalizedColumn.includes(normalizedProp) ||
      normalizedProp.includes(normalizedColumn)
    ) {
      return prop.key
    }
  }

  // Common variations
  const commonMappings: Record<string, string[]> = {
    firstName: ['first', 'firstname', 'fname', 'givenname', 'vorname'],
    lastName: ['last', 'lastname', 'lname', 'surname', 'nachname'],
    email: ['email', 'emailaddress', 'mail'],
    mobilePhone: [
      'phone',
      'phonenumber',
      'telephone',
      'mobile',
      'cell',
      'telefon',
      'mobiltelefon',
    ],
    company: ['company', 'organization', 'org', 'employer', 'firm'],
    jobTitle: ['title', 'jobtitle', 'position', 'role', 'beruf'],
    'address.country': [
      'country',
      'countrycode',
      'countryname',
      'land',
      'landcode',
    ],
    'address.city': ['city', 'cityname', 'stadt', 'stadtname'],
    'address.zipCode': ['zip', 'zipcode', 'zipcode', 'plz', 'plzcode'],
    'address.street': [
      'street',
      'streetaddress',
      'address',
      'strasse',
      'strassename',
    ],
    workPhone: ['workphone', 'work phone', 'telefonnummer', 'firmentelefon'],
    fax: ['fax', 'faxnumber', 'faxphone', 'fax', 'faxnummer'],
    salutation: ['salutation', 'anrede'],
    professional: ['professional', 'academic title', 'academic', 'titel'],
  }

  for (const [propKey, variations] of Object.entries(commonMappings)) {
    if (variations.some(v => normalizedColumn.includes(v))) {
      return propKey
    }
  }

  return null
}

export const MapStep: FC<MapStepProps> = ({
  onNext,
  onBack,
  file,
  onColumnMappingChange,
  columnMapping: initialColumnMapping,
  attendeeProperties,
}) => {
  const { t } = useTranslation()
  const [fileColumns, setFileColumns] = useState<string[]>([])
  const [columnMapping, setColumnMapping] =
    useState<Record<string, string>>(initialColumnMapping)

  useEffect(() => {
    if (file) {
      Papa.parse(file, {
        preview: 1,
        header: true,
        complete: results => {
          const headers = results.meta.fields || []
          setFileColumns(headers)

          // Only auto-map if there's no existing mapping
          if (Object.keys(initialColumnMapping).length === 0) {
            const autoMapping: Record<string, string> = {}
            headers.forEach(column => {
              const match = findBestMatch(column, attendeeProperties)
              if (match) {
                autoMapping[match] = column
              }
            })
            setColumnMapping(autoMapping)
            onColumnMappingChange(autoMapping)
          }
        },
        error: error => {
          console.error('Error parsing CSV:', error)
        },
      })
    }
  }, [file, initialColumnMapping])

  return (
    <div className='space-y-6'>
      <div className='space-y-2'>
        <h2 className='text-2xl font-semibold'>
          {t('import_attendees.map_step.title')}
        </h2>
        <p className='text-muted-foreground'>
          {t('import_attendees.map_step.description')}
        </p>
      </div>

      <div className='grid gap-3'>
        <div className='flex justify-between text-sm font-medium text-muted-foreground'>
          <div>
            {t('import_attendees.map_step.properties_in_conference_direct')}
          </div>
          <div>{t('import_attendees.map_step.columns_in_your_file')}</div>
        </div>

        {attendeeProperties.map(property => (
          <div key={property.key} className='flex items-center gap-3'>
            {/* Property Display */}
            <div className='flex-1 rounded-lg bg-muted/50 p-3'>
              <div className='flex items-center gap-2'>
                <FileSpreadsheet className='h-4 w-4 text-muted-foreground' />
                <span>{t(property.label)}</span>
              </div>
            </div>

            {/* Arrow */}
            <div className='flex w-8 justify-center'>
              <span className='text-muted-foreground'>→</span>
            </div>

            {/* File Column Selector */}
            <div className='flex-1'>
              <div className='relative'>
                <Select
                  value={columnMapping[property.key] || '__none'}
                  onValueChange={value => {
                    const newMapping = { ...columnMapping }

                    // Remove any existing mapping for this property
                    const existingColumn = columnMapping[property.key]
                    if (existingColumn) {
                      delete newMapping[property.key]
                    }

                    // Add new mapping if not "do not import"
                    if (value !== '__none') {
                      newMapping[property.key] = value
                    }

                    setColumnMapping(newMapping)
                    onColumnMappingChange(newMapping)
                  }}
                >
                  <SelectTrigger className='w-full'>
                    <SelectValue placeholder={t('Do not import')} />
                  </SelectTrigger>
                  <SelectContent>
                    <SelectItem value='__none'>{t('Do not import')}</SelectItem>
                    {fileColumns.map(column => (
                      <SelectItem key={column} value={column}>
                        {column}
                      </SelectItem>
                    ))}
                  </SelectContent>
                </Select>
              </div>
            </div>

            {/* Status Icon */}
            <div className='w-8 text-center'>
              {Object.keys(columnMapping).includes(property.key) ? (
                <Check className='h-4 w-4 text-green-500' />
              ) : (
                <Info className='h-4 w-4 text-muted-foreground' />
              )}
            </div>
          </div>
        ))}
      </div>

      <div className='flex justify-between pt-4'>
        <Button variant='outline' onClick={onBack} className='px-6'>
          {t('import_attendees.back')}
        </Button>
        <Button onClick={onNext} className='bg-primary px-6'>
          {t('import_attendees.next')}
        </Button>
      </div>
    </div>
  )
}
