import { MOVE_EVENT_ORGANIZATION, REMOVE_EVENT } from 'graphql/mutations'
import { GET_EVENTS } from 'graphql/queries'
import { FC, useEffect, useRef, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { setOptionWidth } from 'store/Events'

import { styled, useHistory, useMutation } from 'utils/adapters'
import {
  defaultTimezone,
  getCancelLabelAttendeeStatus,
  getEventDate,
  getEventTime,
  getMonthName,
  isAttendee,
  isAttendeeCancelled,
  isRootAdmin,
  priceFormat,
  transformAddress,
} from 'utils/helpers'
import {
  useAppDispatch,
  useAppSelector,
  useModal,
  useSearch,
} from 'utils/hooks'

import { IEvent } from 'models'

import Loading from 'components/atoms/Loading'
import Permission from 'components/atoms/Permission'
import { EventType } from 'constants/events'
import { USER_ROLE, USER_SCOPE } from 'constants/userRole'

import { BadgeCurrency } from '@/components/atoms/BadgeCurrency'
import Overlay from '@/components/atoms/Overlay'
import Modal from '@/components/molecules/Modal'
import OrganizationMenu from '@/components/organisms/OrganizationMenu'
import { Badge } from '@/components/ui/badge'
import { Button } from '@/components/ui/button'
import { Drawer, DrawerTrigger } from '@/components/ui/drawer'
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuSeparator,
  DropdownMenuTrigger,
} from '@/components/ui/dropdown-menu'
import { toast } from '@/components/ui/use-toast'
import { COLORS } from '@/constants/colors'
import { SORT_BY_TYPE, SORT_TYPE } from '@/constants/sortEvent'
import { cn } from '@/lib/utils'
import useDateRange from '@/utils/hooks/useDateRange'
import {
  Ban,
  ChevronRight,
  Clock,
  Copy,
  Eye,
  Globe2,
  MapPin,
  ScissorsSquareDashedBottom,
  UserCog,
  Users,
  XCircle,
} from 'lucide-react'
import { CopyToClipboard } from 'react-copy-to-clipboard'
import useOrganizationIdVar from 'utils/hooks/useOrganizationIdVar'
import CancelModal from './components/CancelModal'

interface IProps {
  event: IEvent
  idx: number
}

const EventItem: FC<IProps> = ({ event, idx }) => {
  const {
    _id,
    title,
    address,
    startEvent,
    endEvent,
    price,
    currency,
    type,
    attendeesCount,
    isExpired,
    timezone,
    mainLanguage,
    eventSetting,
    attendeeStatus,
  } = event
  const { t } = useTranslation()
  const { lang } = useAppSelector(state => state.appReducer)

  const { organizationId } = useOrganizationIdVar()
  const { filterByDate } = useDateRange()
  const { searchKey, setSearchKey } = useSearch()
  const history = useHistory()
  const dispatch = useAppDispatch()
  const { isShow, showModal, hideModal } = useModal()
  const [isShowCancelBookingModal, setIsShowCancelBookingModal] =
    useState<boolean>(false)
  const [registerLink, setRegisterLink] = useState<string>('')

  useEffect(() => {
    if (event?.slug) {
      setRegisterLink(
        `${import.meta.env.VITE_REGISTER_EVENT}/events/${event?.slug}`
      )
    } else {
      setRegisterLink(`${import.meta.env.VITE_REGISTER_EVENT}/events/${_id}`)
    }
  }, [event?.slug])

  const optionRef = useRef<any>()

  useEffect(() => {
    const optionWidth = optionRef?.current?.clientWidth
    if (optionWidth) dispatch(setOptionWidth(optionWidth))
  })

  const [mutationRemoveEvent] = useMutation(REMOVE_EVENT, {
    onCompleted({ removeEvent }) {
      if (removeEvent) {
        toast({
          description: t('Remove event success!'),
          variant: 'default',
        })
        hideModal()
      }
    },
    refetchQueries: [
      {
        query: GET_EVENTS,
        variables: {
          filter: {
            title: searchKey.length > 0 ? searchKey : '',
            isExpired: localStorage.getItem('tabState') === 'true',
            filterBy:
              localStorage.getItem('eventOrderBy') || SORT_BY_TYPE.START_DATE,
            direction: localStorage.getItem('eventDirection') || SORT_TYPE.DESC,
            ...(filterByDate.fromTime &&
              filterByDate.toTime && {
                fromTime: filterByDate.fromTime.toISOString(),
                toTime: filterByDate.toTime.toISOString(),
              }),
          },
          organizationId: organizationId ? organizationId : undefined,
        },
      },
    ],
    onError: err => {
      err.graphQLErrors.map(({ message }) => {
        toast({
          title: t('Error'),
          description: t(message),
          variant: 'destructive',
        })
      })
    },
  })

  const [
    mutateMoveEventToOrganization,
    { loading: moveEventToOrganizationLoading },
  ] = useMutation(MOVE_EVENT_ORGANIZATION, {
    onCompleted: ({ moveEventOrganization }) => {
      toast({
        description: t('Move event {{title}} success!', {
          title: moveEventOrganization.title,
        }),
        variant: 'destructive',
      })
    },
    refetchQueries: [
      {
        query: GET_EVENTS,
        variables: {
          filter: {
            title: searchKey.length > 0 ? searchKey : '',
            isExpired: localStorage.getItem('tabState') === 'true',
            filterBy:
              localStorage.getItem('eventOrderBy') || SORT_BY_TYPE.START_DATE,
            direction: localStorage.getItem('eventDirection') || SORT_TYPE.DESC,
            ...(filterByDate.fromTime &&
              filterByDate.toTime && {
                fromTime: filterByDate.fromTime.toISOString(),
                toTime: filterByDate.toTime.toISOString(),
              }),
          },

          organizationId: organizationId ? organizationId : undefined,
        },
      },
    ],
    onError: err => {
      toast({
        title: t('Error'),
        description: t(err.message),
        variant: 'destructive',
      })
    },
    fetchPolicy: 'network-only',
  })

  const redirectEventDetail = () => {
    sessionStorage.setItem(
      'scrollPosition',
      window.pageYOffset as unknown as string
    )
    if (isAttendee()) {
      history.push(`attendee/events/${_id}`)
    } else {
      history.push(`admin/events/${_id}`)
    }
  }

  const onConfirmRemoveEvent = (eventId: string) => {
    mutationRemoveEvent({
      variables: {
        removeEventId: eventId,
      },
    })
  }

  const moveEventToOrganization = (selectedOrganizationId?: string) => {
    mutateMoveEventToOrganization({
      variables: {
        eventId: _id,
        orgId: selectedOrganizationId,
      },
    })
  }

  const editBooking = () => {
    setSearchKey('')
    history.push(`events/${_id}/edit-booking`)
  }

  const cancelBooking = () => {
    setIsShowCancelBookingModal(true)
  }

  if (moveEventToOrganizationLoading) {
    return (
      <>
        <Loading />
        <OverLayLoading isToggle={moveEventToOrganizationLoading} />
      </>
    )
  }

  const isDigitalEvent = type === EventType.DIGITAL
  let tz = timezone || defaultTimezone

  if (isDigitalEvent) {
    const { timeZone: clientTimeZone } = Intl.DateTimeFormat().resolvedOptions()
    tz = clientTimeZone
  }

  const { isTheSameDay, startTime, endTime, utcOffset, isTheSameTime } =
    getEventTime(mainLanguage, tz, startEvent, endEvent)

  const isCancelled = isAttendeeCancelled(attendeeStatus || '')
  const { label: cancelLabel, color: cancelColor } =
    getCancelLabelAttendeeStatus(attendeeStatus || '')

  const startDate = getEventDate(event?.startEvent, tz)

  const startMonth = getMonthName(startDate)
  const startDay = startDate.getDate()
  const startYear = startDate.getFullYear()

  const endDate = getEventDate(event?.endEvent, tz)
  const endMonth = getMonthName(endDate)
  const endDay = endDate.getDate()
  const endYear = endDate.getFullYear()

  const copyLinkEvent = () => {
    toast({
      variant: 'default',
      description: t('Copied to clipboard!'),
    })
  }

  return (
    <>
      <div
        className={cn(
          'transition-all duration-500 ease-linear event-item w-full text-primary shadow-sm relative flex flex-col rounded-xl',
          idx % 2 !== 0
            ? 'bg-white bg-accent border'
            : 'border bg-card hover:border',
          isCancelled && 'overflow-hidden'
        )}
      >
        <div
          // onClick={onClickEventItem}
          className='space-y-2 p-2'
        >
          <div className='relative event-item__child flex flex-col w-full md:flex-row '>
            {isCancelled && (
              <CancelledLabel color={cancelColor}>
                {t(cancelLabel)}
              </CancelledLabel>
            )}

            <div className='flex mx-auto items-center gap-4'>
              <div
                className='flex gap-1 flex-row justify-around font-semibold leading-none text-gray-800
              uppercase rounded md:flex-col md:items-center md:justify-center w-[80px] md:gap-0'
              >
                <span className='md:text-[17px] text-primary'>
                  {startMonth}
                </span>
                <div className='md:text-[38px] md:leading-9'>{startDay}</div>
                <div className='md:font-normal md:text-[13px] text-zinc-500'>
                  {startYear}
                </div>
              </div>

              {!isTheSameDay && (
                <div className='flex items-center mr-2'>
                  <ChevronRight className='mx-2 md:mx-0' />
                  <div
                    className='flex gap-1 flex-row justify-around font-semibold leading-none text-gray-800
              uppercase rounded md:flex-col md:items-center md:justify-center w-[80px] md:gap-0'
                  >
                    <div className='md:text-[17px] text-primary'>
                      {endMonth}
                    </div>
                    <div className='md:text-[38px] md:leading-9'>{endDay}</div>
                    <div className='md:font-normal md:text-[13px] text-zinc-500'>
                      {endYear}
                    </div>
                  </div>
                </div>
              )}
            </div>
            <div className='text-gray-800 w-full px-7 md:border-l flex flex-col justify-center'>
              <div>
                <p className='text-lg font-semibold text-start text-primary relative'>
                  {title}
                  {eventSetting?.isLockedRegister && (
                    <span className='absolute ml-2'>
                      <Badge variant='outline' className='text-primary'>
                        {t('Locked register')}
                      </Badge>
                    </span>
                  )}
                </p>
              </div>

              <div className='flex flex-col gap-1 text-zinc-600'>
                <>
                  <div className='space-y-2 md:flex justify-between'>
                    <div className='flex items-center gap-1 justify-start'>
                      {!isDigitalEvent ? (
                        <>
                          <MapPin width='16' height='16' />
                          <p className='text-sm'>
                            {event?.hotelName ? `${event?.hotelName}, ` : ''}
                            {transformAddress(
                              event?.address?.street as string,
                              '',
                              event?.address?.city as string,
                              event?.address?.country as string
                            )}
                          </p>
                        </>
                      ) : (
                        <>
                          <Globe2 width='16' height='16' />
                          <p>{t('Digital')}</p>
                        </>
                      )}
                    </div>
                    {event?.price !== 0 && (
                      <div className='flex items-center gap-1 justify-start'>
                        <BadgeCurrency currency={event?.currency} />
                        <p className='text-sm'>
                          {priceFormat(event?.price, lang)} / {t('person')}
                        </p>
                      </div>
                    )}
                  </div>
                </>
                <div className='flex justify-between'>
                  {isTheSameDay && (
                    <div className='flex items-center gap-1 justify-start'>
                      <Clock width='16' height='16' />
                      <p className='text-sm'>
                        {isTheSameTime
                          ? `${startTime} (GMT${utcOffset})`
                          : `${startTime} - ${endTime} (GMT${utcOffset})`}
                      </p>
                    </div>
                  )}
                  {attendeesCount > 0 && (
                    <div className='flex items-center gap-1 justify-start'>
                      <Users width='16' height='16' />
                      <p className='text-sm'>{attendeesCount}</p>
                    </div>
                  )}
                </div>
              </div>
            </div>
            <div className='items-center justify-center flex pr-4'>
              <Drawer>
                <DropdownMenu>
                  <DropdownMenuTrigger asChild>
                    <Button variant='outline'>{t('Open')}</Button>
                  </DropdownMenuTrigger>
                  <DropdownMenuContent>
                    <DropdownMenuItem onClick={redirectEventDetail}>
                      <Eye className='w-4 h-4 mr-1' />
                      {t('Event Detail')}
                    </DropdownMenuItem>
                    <CopyToClipboard text={registerLink} onCopy={copyLinkEvent}>
                      <DropdownMenuItem>
                        <Copy className='w-4 h-4 mr-1' />
                        <span>{t('Get Link')}</span>
                      </DropdownMenuItem>
                    </CopyToClipboard>
                    <Permission
                      role={USER_ROLE.ATTENDEE}
                      ignoreRootAdmin={true}
                    >
                      <DropdownMenuItem
                        disabled={
                          isExpired || isCancelled || Boolean(event?.price)
                        }
                        // TODO: un-comment below code
                        // disabled={isExpired || isCancelled}
                        onClick={
                          !isExpired && !isCancelled ? editBooking : undefined
                        }
                      >
                        <UserCog className='w-4 h-4 mr-1' />
                        <span>{t('Edit Booking')}</span>
                      </DropdownMenuItem>
                    </Permission>
                    <Permission
                      role={USER_ROLE.ATTENDEE}
                      ignoreRootAdmin={true}
                      isShow={eventSetting?.cancellation || false}
                    >
                      <DropdownMenuItem
                        disabled={
                          isExpired || isCancelled || Boolean(event?.price)
                        }
                        // TODO: un-comment below code
                        // disabled={isExpired || isCancelled}
                        onClick={
                          !isExpired && !isCancelled ? cancelBooking : undefined
                        }
                      >
                        <Ban className='w-4 h-4 mr-1' color='#ff0000' />
                        <span style={{ color: 'red' }}>
                          {t('Cancel Booking')}
                        </span>
                      </DropdownMenuItem>
                    </Permission>
                    <Permission role={USER_ROLE.ROOT_ADMIN}>
                      <DropdownMenuItem>
                        <DrawerTrigger className='flex'>
                          <ScissorsSquareDashedBottom className='w-4 h-4 mr-1' />
                          <span>{t('Move Organization')}</span>
                        </DrawerTrigger>
                      </DropdownMenuItem>
                    </Permission>

                    <Permission scope={USER_SCOPE.EVENT_DELETE}>
                      <DropdownMenuSeparator />
                      <DropdownMenuItem>
                        <XCircle className='w-4 h-4 mr-1' color='#ff0000' />
                        <span onClick={showModal}>{t('Remove Event')}</span>
                      </DropdownMenuItem>
                    </Permission>
                  </DropdownMenuContent>
                </DropdownMenu>
                {isRootAdmin() && (
                  <OrganizationMenu
                    title={event?.title}
                    currentOrganizationId={organizationId}
                    onClick={moveEventToOrganization}
                  />
                )}
              </Drawer>
            </div>
          </div>
        </div>
        <Modal
          isShow={isShow}
          title={t('Warning!')}
          onClose={hideModal}
          onConfirm={() => onConfirmRemoveEvent(_id)}
          description={`${t('Are you sure to remove this event?')} ${t(
            'You will permanently lose your data.'
          )}`}
        />
      </div>
      <CancelModal
        isShowCancelBookingModal={isShowCancelBookingModal}
        setIsShowCancelBookingModal={setIsShowCancelBookingModal}
        _id={_id}
        currency={currency}
      />
    </>
  )
}

const OverLayLoading = styled(Overlay)`
  z-index: 15;
`

const CancelledLabel = styled.span<{ color: COLORS }>`
  color: white;
  background: ${props => props.color};
  padding: 4px 20px;
  border-radius: 0px;
  font-size: 10px;
  font-weight: bold;
  position: absolute;
  top: 1px;
  right: -29px;
  transform: rotate(36deg);
  overflow: hidden;
`

export default EventItem
