import { gql } from 'utils/adapters'

export const GET_EVENTS = gql`
  query GetEvents($filter: EventFilter, $organizationId: ID) {
    events(filter: $filter, organizationId: $organizationId) {
      _id
      title
      description
      address {
        city
        country
        street
      }
      startEvent
      endEvent
      price
      currency
      attendeesCount
      isExpired
      type
      slug
      eventSetting {
        isLockedRegister
      }
    }
  }
`

export const GET_ATTENDEE_EVENTS = gql`
  query GetAttendeeEvents($filter: EventFilter) {
    events: attendeeEvents(filter: $filter) {
      _id
      title
      description
      address {
        city
        country
        street
      }
      startEvent
      endEvent
      price
      currency
      isExpired
      type
      eventSetting {
        cancellation
      }
      attendeeStatus
    }
  }
`

export const PUBLIC_GET_EVENT_BY_ID = gql`
  query PublicGetEventById($id: ID, $slug: String) {
    event: publicEvent(id: $id, slug: $slug) {
      _id
      title
      description
      capacity
      address {
        city
        country
        street
        zipCode
      }
      startEvent
      endEvent
      price
      currency
      isExpired
      eventSetting {
        primaryColor
        logo
        fontColor
        eventPicture
        backgroundPicture
        backgroundTransparent
        logoSize
        isLockedRegister
        bgColor
        bgColorOpacity
        isShowPrice
        layoutType
      }
      coordinates {
        displayName
        lat
        lng
      }
      isBookable
      hotelBookingUrl
      agendaUrl
      hotelName
      isBusinessEvent
      timezone
      type
      mainLanguage
      customFields {
        isRequired
        options {
          fee
          name
        }
        title
        type
        _id
      }
      customSelections {
        required
        options {
          label
          value
        }
        label
        id
        type
        validation
        enabled
        systemField
      }
      toggleDefaultFields {
        firstName {
          enabled
          required
        }
        lastName {
          required
          enabled
        }
        salutation {
          enabled
          required
        }
        professional {
          required
          enabled
        }
        jobTitle {
          enabled
          required
        }
        company {
          required
          enabled
        }
        address {
          street {
            required
            enabled
          }
          zipCode {
            required
            enabled
          }
          city {
            required
            enabled
          }
          country {
            required
            enabled
          }
        }
        workPhone {
          required
          enabled
        }
        mobilePhone {
          required
          enabled
        }
        fax {
          required
          enabled
        }
      }
    }
  }
`

export const ATTENDEE_GET_EVENT_BY_ID = gql`
  query AttendeeGetEventById($id: ID!) {
    event: attendeeEvent(id: $id) {
      _id
      title
      description
      capacity
      address {
        city
        country
        street
        zipCode
      }
      startEvent
      endEvent
      price
      currency
      isExpired
      attendeeStatus
      eventSetting {
        primaryColor
        fontColor
        logo
        eventPicture
        backgroundPicture
        cancellation
        backgroundTransparent
        logoSize
        bgColor
        bgColorOpacity
        layoutType
      }
      coordinates {
        displayName
        lat
        lng
      }
      isBookable
      hotelBookingUrl
      hotelName
      isBusinessEvent
      timezone
      type
      mainLanguage
      customSelections {
        required
        options {
          label
          value
        }
        label
        id
        type
        validation
        enabled
        systemField
      }
      customFields {
        _id
        createdAt
        isRequired
        options {
          fee
          name
        }
        title
        type
      }
      toggleDefaultFields {
        firstName {
          enabled
          required
        }
        lastName {
          required
          enabled
        }
        salutation {
          enabled
          required
        }
        professional {
          required
          enabled
        }
        jobTitle {
          enabled
          required
        }
        company {
          required
          enabled
        }
        address {
          street {
            required
            enabled
          }
          zipCode {
            required
            enabled
          }
          city {
            required
            enabled
          }
          country {
            required
            enabled
          }
        }
        workPhone {
          required
          enabled
        }
        mobilePhone {
          required
          enabled
        }
        fax {
          required
          enabled
        }
      }
    }
  }
`

export const ADMIN_GET_EVENT_BY_ID = gql`
  query GetEventById($id: ID!) {
    event(id: $id) {
      _id
      title
      description
      capacity
      address {
        city
        country
        street
        zipCode
      }
      startEvent
      endEvent
      price
      currency
      isExpired
      attendeesCount
      slug
      eventSetting {
        isShowPrice
        primaryColor
        bgColor
        bgColorOpacity
        fontColor
        logo
        logoSize
        eventPicture
        backgroundPicture
        backgroundTransparent
        isLockedRegister
        isCalendarEntryEnabled
        cancellationPolicies {
          _id
          createdAt
          endDate
          feeNumber
          feeType
          startDate
        }
        layoutType
      }
      coordinates {
        displayName
        lat
        lng
      }
      isBookable
      hotelBookingUrl
      agendaUrl
      hotelName
      customFields {
        _id
        createdAt
        isRequired
        options {
          fee
          name
        }
        title
        type
      }
      customSelections {
        required
        options {
          label
          value
        }
        label
        id
        type
        validation
        enabled
        systemField
      }
      allQuestions {
        required
        options {
          label
          value
        }
        label
        id
        type
        validation
        enabled
        fieldType
      }
      attendees {
        _id
        firstName
        lastName
        jobTitle
        company
        webcastParticipantId
        webcastUrlJoinLinks
        additionalFields
        address {
          street
          zipCode
          city
          country
        }
        email
        fax
        workPhone
        mobilePhone
        status
        invitationStatus
        customSelection
        registration {
          checked
          registrationCode
          registrationCodes
          registrationDate
          customValues {
            value {
              fee
              name
            }
            eventCustomField {
              _id
              isRequired
              title
              type
              options {
                name
                fee
              }
            }
          }
        }
        cancellation {
          cancellationDate
          cancellationFee
          cancellationReason
          isCancelled
          refundFee
        }
      }
      isBusinessEvent
      timezone
      type
      mainLanguage
      webcastEventId
    }
  }
`

export const GET_EVENT_INPUT = gql`
  query GetEventInput($id: ID!) {
    event(id: $id) {
      _id
      title
      description
      isBusinessEvent
      attendeesCount
      capacity
      address {
        city
        country
        street
        zipCode
      }
      startEvent
      isRawContent
      endEvent
      price
      currency
      sendByOrganizationEmail
      slug
      eventSetting {
        isShowPrice
        primaryColor
        fontColor
        logo
        eventPicture
        backgroundPicture
        cancellation
        backgroundTransparent
        isLockedRegister
        isCalendarEntryEnabled
        logoSize
        bgColor
        bgColorOpacity
        cancellationPolicies {
          feeType
          feeNumber
          startDate
          endDate
          _id
        }
        layoutType
      }
      toggleDefaultFields {
        firstName {
          enabled
          required
        }
        lastName {
          required
          enabled
        }
        salutation {
          enabled
          required
        }
        professional {
          required
          enabled
        }
        jobTitle {
          enabled
          required
        }
        company {
          required
          enabled
        }
        address {
          street {
            required
            enabled
          }
          zipCode {
            required
            enabled
          }
          city {
            required
            enabled
          }
          country {
            required
            enabled
          }
        }
        workPhone {
          required
          enabled
        }
        mobilePhone {
          required
          enabled
        }
        fax {
          required
          enabled
        }
      }
      customFields {
        _id
        title
        options {
          name
          fee
        }
        isRequired
        type
      }
      customSelections {
        required
        options {
          label
          value
        }
        label
        id
        type
        validation
        enabled
        systemField
      }
      isBookable
      hotelBookingUrl
      agendaUrl
      hotelName
      emailContent
      sendQrCode
      type
      mainLanguage
      webcastEventId
      shouldCreateAttendantUser
      organization {
        _id
        capacity
        email
        eventSourceId
        isRemoved
        name
      }
      emailAttachments {
        uri
        filename
      }
    }
  }
`

export const SEARCH_EVENTS = gql`
  query SearchEvents($organizationId: ID, $searchKey: String!) {
    searchEvent(organizationId: $organizationId, searchKey: $searchKey) {
      _id
      title
      description
      address {
        city
        country
        street
      }
      startEvent
      endEvent
      price
      attendeesCount
      isExpired
      type
    }
  }
`

export const ATTENDEE_SEARCH_EVENTS = gql`
  query AttendeeSearchEvents($searchKey: String!) {
    searchEvent: attendeeSearchEvent(
      organizationId: $organizationId
      searchKey: $searchKey
    ) {
      _id
      title
      description
      address {
        city
        country
        street
      }
      startEvent
      endEvent
      price
      attendeesCount
      isExpired
      type
      organization {
        name
      }
    }
  }
`

export const CUSTOM_FIELDS = gql`
  query CustomFields($organizationId: ID) {
    customFields(organizationId: $organizationId) {
      _id
      title
      options {
        name
        fee
      }
      type
      isRequired
    }
  }
`

export const EXPORT_EVENT_ATTENDEES = gql`
  query exportEventAttendees(
    $eventId: ID!
    $status: String
    $fields: [String!]
  ) {
    exportEventAttendeesSelectField(
      eventId: $eventId
      status: $status
      fields: $fields
    ) {
      status
      message
      data
    }
  }
`
export const FETCH_PLACEHOLDERS = gql`
  query FetchPlaceHolders {
    fetchPlaceHolders {
      name
    }
  }
`
export const CHECK_EVENT_ATTENDEE = gql`
  query CheckEventAttendee($attendeeEmail: String!, $eventId: ID!) {
    checkEventAttendee(attendeeEmail: $attendeeEmail, eventId: $eventId) {
      message
      status
    }
  }
`

export const GET_LANGUAGES = gql`
  query GetLanguage {
    fetchLanguages {
      data
    }
  }
`

export const GET_SALUTATIONS = gql`
  query GetSalutations($language: Language) {
    fetchSalutations(language: $language) {
      data {
        key
        value
      }
    }
  }
`

export const GET_PROFESSIONALS = gql`
  query GetProfessionals($language: Language) {
    fetchProfessionals(language: $language) {
      data {
        key
        value
      }
    }
  }
`

export const GET_CANCELLATION_FEE = gql`
  query cancellationFee($eventId: ID!) {
    cancellationFee(eventId: $eventId) {
      email
      fee
      refundFee
    }
  }
`

export const GET_EVENT_TERMS = gql`
  query GetEventTerms($id: ID!) {
    getEventTerms(id: $id) {
      terms
    }
  }
`
