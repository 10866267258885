import React, { FC, useCallback, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'

import { SEND_NEWSLETTER, UPLOAD_FILES } from 'graphql/mutations'
import { FETCH_PLACEHOLDERS } from 'graphql/queries'
import { setEmailPlaceHolders } from 'store/Events'

import {
  Field,
  Form,
  styled,
  useMutation,
  useParams,
  useQuery,
} from 'utils/adapters'
import { trimWhiteSpace } from 'utils/helpers'
import { useAppDispatch, useAppSelector } from 'utils/hooks'

import { Button } from '@/components/ui/button'
import Editor from 'components/atoms/Editor' // import Input from 'components/atoms/Input'
import { TestEmailNewsletterContainer } from 'components/molecules/TestEmail'
import { FileType } from 'models'
import EventAttachments from '../EventInformation/components/EventAttachments'
import RecipientTable from './RecipientTable'
import { Input } from '@/components/ui/input'
import { setNewLetter } from '@/store/Events/EventsSlice'
import { toast } from '@/components/ui/use-toast'
import { Icons } from '@/components/ui/icon'
import { FormItem, FormLabel } from '@/components/ui/final-form'

type ParamsType = {
  id: string
}

const EmailNewsLetter: FC = () => {
  const { t } = useTranslation()

  const { id } = useParams<ParamsType>()
  const dispatch = useAppDispatch()
  const { selectedRecipents } = useAppSelector(state => state.userReducer)
  const { emailPlaceHolders, eventDetail } = useAppSelector(
    state => state.eventsReducer
  )
  const [files, setFiles] = useState<FileType[]>([])
  const [filesLoading, setFilesLoading] = useState(false)
  const [attachmentErrorMessage, setAttachmentErrorMessage] =
    useState<string>('')
  const { newLetter } = useAppSelector(state => state.eventsReducer)
  const { data } = useQuery(FETCH_PLACEHOLDERS)

  const [mutateUploadFiles] = useMutation(UPLOAD_FILES, {
    onCompleted: ({ uploadFiles }) => {
      const mappedFiles = uploadFiles.map((file: FileType) => ({
        uri: file.uri,
        filename: file.filename,
        mimetype: file.mimetype,
      })) as FileType[]

      const convertedFiles = mappedFiles
        .concat(files)
        .filter((file: FileType) => file)

      setFiles(convertedFiles)
      setFilesLoading(false)
    },
    onError: err => {
      toast({
        title: t('Error'),
        description: t(err.message),
        variant: 'destructive',
      })

      setFilesLoading(false)
    },
  })

  const [mutateSendNewsletter, { loading }] = useMutation(SEND_NEWSLETTER, {
    onCompleted: ({ sendNewsletter }) => {
      if (sendNewsletter && sendNewsletter.status === 200) {
        setFiles([])
        toast({
          description: t('Send email success'),
        })
      }
    },

    onError: err => {
      toast({
        title: t('Error'),
        description: t(err.message),
        variant: 'destructive',
      })
    },
  })
  useEffect(() => {
    if (data) {
      const { fetchPlaceHolders } = data
      dispatch(setEmailPlaceHolders(fetchPlaceHolders))
    }
  }, [data])

  useEffect(() => {
    return () => {
      dispatch(setNewLetter(''))
    }
  }, [])

  const removeLocalAttachment = (index: number) => {
    setFiles(files.filter((_: FileType, i: number) => i !== index))
  }

  const onChangeEditor = (value: string) => {
    dispatch(setNewLetter(value))
  }

  const uploadEmailAttachments = useCallback(files => {
    let total = 0
    files.forEach((file: File) => {
      total += file.size
    })

    if (total > 50000000) {
      const message = t('File(s) size must be less than 50Mb')
      setAttachmentErrorMessage(message)

      setTimeout(() => {
        setAttachmentErrorMessage('')
      }, 8000)
    } else {
      if (files.length > 0) {
        setFilesLoading(true)
        mutateUploadFiles({ variables: { files } })
      }
    }
  }, [])

  const onSubmit = (values: any) => {
    const formData = {
      eventId: id,
      attendees: selectedRecipents,
      content: newLetter,
      subject: values?.subject,
      attachments: files,
    }

    mutateSendNewsletter({
      variables: {
        eventNewsletterInput: formData,
      },
    })
  }

  const required = (value: any) =>
    value ? undefined : t('This field is required!')

  return (
    <Wrapper>
      <Container>
        <Form
          onSubmit={onSubmit}
          keepDirtyOnReinitialize
          subscription={{
            submitting: true,
          }}
          render={({ form, handleSubmit, submitting, pristine }) => {
            const disabled =
              submitting ||
              pristine ||
              selectedRecipents.length === 0 ||
              filesLoading ||
              loading
            return (
              <FormContainer
                onSubmit={event => {
                  handleSubmit(event)
                  form.reset()
                }}
              >
                <RecipientTable event={eventDetail} />

                <Field
                  name='subject'
                  type='text'
                  validate={required}
                  format={trimWhiteSpace}
                  formatOnBlur
                >
                  {({ input, meta }: any) => (
                    <FormItem>
                      <FormLabel meta={meta} isRequired>
                        {t('Subject')}
                      </FormLabel>
                      <Input {...input} isRequired={true} maxLength={255} />
                    </FormItem>
                  )}
                </Field>
                <EventAttachments
                  files={files}
                  removeLocalAttachment={removeLocalAttachment}
                  onDrop={uploadEmailAttachments}
                  errorMessage={attachmentErrorMessage}
                  uploadLoading={filesLoading}
                />

                <Editor
                  className='content-editor'
                  value={newLetter}
                  onChange={onChangeEditor}
                  emailPlaceHolders={emailPlaceHolders}
                  isMention={true}
                  displayInsertAsHtml
                />
                <div className='w-full flex justify-center gap-4'>
                  <TestEmailNewsletterContainer
                    disabled={submitting || pristine || filesLoading || loading}
                    action={'newsletter'}
                    files={files}
                    eventId={id}
                  />
                  <Button type='submit' disabled={disabled} className='w-full'>
                    {loading && (
                      <Icons.spinner className='mr-2 h-4 w-4 animate-spin' />
                    )}
                    {t('Send Email')}
                  </Button>
                </div>
              </FormContainer>
            )
          }}
        />
      </Container>
    </Wrapper>
  )
}

const Wrapper = styled.div`
  width: 100%;
  height: 100%;
`

const Container = styled.div`
  padding: 2%;
  box-sizing: border-box;
  width: 100%;
`

const FormContainer = styled.form`
  display: flex;
  flex-direction: column;
  gap: 10px;
`

export default EmailNewsLetter
