import 'react-color-palette/lib/css/styles.css'

import { FC, useCallback, useRef, useState } from 'react'
import { useTranslation } from 'react-i18next'

import { Switch } from '@/components/ui/switch'
import { ColorPicker, useColor } from 'react-color-palette'

import { UPLOAD_FILE, UPLOAD_FILES } from 'graphql/mutations'
import { setFormData, setStep } from 'store/Events'
import {
  blockTypingNonNumberic,
  formatPrice,
  hotelBookingURLValidation,
  trimWhiteSpace,
  trimZeroNumber,
} from 'utils/helpers'

import { Field, Form, styled, useMutation } from 'utils/adapters'
import { useAppDispatch, useAppSelector, useClickOutside } from 'utils/hooks'

import { Button } from '@/components/ui/button'
import { Slider } from '@/components/ui/slider'
import DotsLoading from 'components/atoms/DotsLoading'
import { FileType } from 'models'

import ReactSelectAdapter from '@/components/atoms/Select/SelectAdapter'
import { FormItem, FormLabel } from '@/components/ui/final-form'
import { Input } from '@/components/ui/input'
import { Separator } from '@/components/ui/separator'
import { cn } from '@/lib/utils'
import { PATH_NAME } from '@/routes/routesMap'
import { Currency } from 'constants/currency'
import { t } from 'i18next'
import { ChevronLeft, ScanEye } from 'lucide-react'
import { useParams, useRouteMatch } from 'react-router'
import EventAttachments from './EventAttachments'
import EventUploadFile from './EventUploadFile'
import { toast } from '@/components/ui/use-toast'
import { RenderSwitch } from '@/components/atoms/NewSwitch/new-switch'
import { ACCEPT_FILES, IMAGE_MAX_SIZE } from '@/constants'
import { SaveChangesNowButton } from '@/components/molecules/SaveChangesNow'
import {
  Popover,
  PopoverContent,
  PopoverTrigger,
} from '@/components/ui/popover'
import HelpText from '@/components/atoms/HelpText'
import { checkSlugExists } from 'services/eventServices'
import { RadioGroup, RadioGroupItem } from '@/components/ui/radio-group'
import { EventLayoutType } from '@/constants/events'
import { Label } from '@/components/ui/label'
import EventDetailPreview from './EventDetailPreview'
import { Environment } from '@/environments'

const parse = (value: any) => {
  return !parseFloat(value) || !Number(value) || value.endsWith('.')
    ? value
    : parseFloat(value.replace(/,/g, '.'))
}

const parseCapacity = (value: any) =>
  isNaN(parseInt(value)) ? null : parseInt(value)

const requiredPrice = (value: number) => {
  if (value !== null && value !== undefined) {
    const _value = value?.toString().split('.')
    _value?.shift()
    if (_value?.length && _value[0].length > 2) {
      return t('Price can have only {{number}} digits after .', { number: 2 })
    }
    return undefined
  }
  return t('This field is required!')
}

interface Upload {
  encoding: string
  uri: string
  filename: string
  mimetype: string
  fileKey: string
}

type ParamsType = {
  id: string
}

const SlugCheckingLoader = () => (
  <div className='flex gap-1'>
    {[...Array(3)].map((_, i) => (
      <div
        key={i}
        className='w-1 h-1 rounded-full bg-gray-400 animate-bounce'
        style={{
          animationDelay: `${i * 0.2}s`,
        }}
      />
    ))}
  </div>
)

const EventSetting: FC = () => {
  const { t } = useTranslation()

  const matchEditRoute = useRouteMatch(PATH_NAME.EDIT_EVENT)
  const { id } = useParams<ParamsType>()

  const dispatch = useAppDispatch()
  const { formData, step } = useAppSelector(state => state.eventsReducer)
  const [logoLoading, setLogoLoading] = useState(false)
  const [backgroundLoading, setBackgroundLoading] = useState(false)
  const [filesLoading, setFilesLoading] = useState(false)
  const [toggleColorPicker, setToggleColorPicker] = useState(false)
  const [toggleTextColorPicker, setToggleTextColorPicker] = useState(false)
  const [toggleBackgroundColorPicker, setToggleBackgroundColorPicker] =
    useState(false)
  const [bgOpacity, setBgOpacity] = useState(
    Number(formData?.eventSetting?.backgroundTransparent) * 100 || 30
  )
  const [bgColorOpacity, setBgColorOpacity] = useState(
    Number(formData?.eventSetting?.bgColorOpacity) * 100 || 100
  )

  const [isCustomLogoSize, setIsCustomLogoSize] = useState(
    Boolean(formData?.eventSetting?.logoSize)
  )

  const [logoSize, setLogoSize] = useState(formData?.eventSetting?.logoSize)

  const [color, setColor] = useColor(
    'hex',
    formData?.eventSetting?.primaryColor || '#2C506C'
  )

  const [textColor, setTextColor] = useColor(
    'hex',
    formData?.eventSetting?.fontColor || '#2C506C'
  )

  const [bgColor, setBgColor] = useColor(
    'hex',
    formData?.eventSetting?.bgColor || '#fff'
  )

  const colorPickerRef = useRef(null)
  const textColorPickerRef = useRef(null)

  const [fileLogo, setFileLogo] = useState<Upload>({
    encoding: '',
    uri: formData.eventSetting?.logo ? formData.eventSetting.logo : '',
    filename: '',
    mimetype: '',
    fileKey: '',
  })

  const [fileBackground, setFileBackground] = useState<Upload>({
    encoding: '',
    uri: formData.eventSetting?.backgroundPicture
      ? formData.eventSetting?.backgroundPicture
      : '',
    filename: '',
    mimetype: '',
    fileKey: '',
  })

  const [previewLogo, setPreviewLogo] = useState<any>()
  const [previewBackground, setPreviewBackground] = useState<any>()

  const [files, setFiles] = useState<Upload[]>([])
  const [attachmentErrorMessage, setAttachmentErrorMessage] = useState('')
  const [logoUploadErrorMessage, setLogoUploadErrorMessage] = useState('')
  const [bgUploadErrorMessage, setBgUploadErrorMessage] = useState('')

  const [layoutType, setLayoutType] = useState(
    formData?.eventSetting?.layoutType || EventLayoutType.TWO_SIDED
  )

  const [openPreview, setOpenPreview] = useState(false)
  const [mutateUploadLogo] = useMutation(UPLOAD_FILE, {
    onCompleted: ({ uploadFile }) => {
      setFileLogo(uploadFile)
      setLogoLoading(false)
    },
    onError: err => {
      toast({
        title: t('Error'),
        description: t(err.message),
        variant: 'destructive',
      })
    },
  })

  const [mutateUploadBackground] = useMutation(UPLOAD_FILE, {
    onCompleted: ({ uploadFile }) => {
      setFileBackground(uploadFile)
      setBackgroundLoading(false)
    },
    onError: err => {
      toast({
        title: t('Error'),
        description: t(err.message),
        variant: 'destructive',
      })
    },
  })

  const [mutateUploadFiles] = useMutation(UPLOAD_FILES, {
    onCompleted: ({ uploadFiles }) => {
      const mappedFiles = uploadFiles.map((file: Upload) => ({
        uri: file.uri,
        filename: file.filename,
        mimetype: file.mimetype,
      }))

      dispatch(
        setFormData({
          ...formData,
          emailAttachments: mappedFiles
            .concat(formData.emailAttachments)
            .filter((file: FileType) => file),
        })
      )
      setFilesLoading(false)
    },
    onError: err => {
      toast({
        title: t('Error'),
        description: t(err.message),
        variant: 'destructive',
      })

      setFilesLoading(false)
    },
  })

  useClickOutside(colorPickerRef, () => {
    setToggleColorPicker(false)
  })

  useClickOutside(textColorPickerRef, () => {
    setToggleTextColorPicker(false)
  })

  const [isCheckingSlug, setIsCheckingSlug] = useState(false)

  const validateSlug = async (value: string) => {
    if (!value) return undefined

    if (matchEditRoute && value === formData.slug) {
      return undefined
    }

    try {
      setIsCheckingSlug(true)
      const response = await checkSlugExists(value, id)
      if (response.data.data.exists) {
        return t('This URL slug is already taken')
      }
    } catch (error) {
      console.error('Error checking slug:', error)
    } finally {
      setIsCheckingSlug(false)
    }

    return undefined
  }

  const onSubmit = async (values: any) => {
    if (values.slug) {
      const slugError = await validateSlug(values.slug)
      if (slugError) {
        return toast({
          title: t('Error'),
          description: slugError,
          variant: 'destructive',
        })
      }
    }

    const eventFormData = handleSetFormData(values)
    dispatch(setFormData(eventFormData))
    dispatch(setStep(step + 1))
  }

  const onDropLogo = useCallback(([logoFile]) => {
    if (logoFile.size > IMAGE_MAX_SIZE) {
      setLogoUploadErrorMessage(
        t('File {{fileName}} is too large. Maximum size is 5 MB', {
          fileName: logoFile.name,
        })
      )
    } else {
      if (checkSupportFile(logoFile)) {
        setLogoUploadErrorMessage('')
        setLogoLoading(true)
        mutateUploadLogo({ variables: { file: logoFile } })
        setPreviewLogo(
          [logoFile].map(file =>
            Object.assign(file, {
              preview: URL.createObjectURL(file),
            })
          )
        )
      } else {
        setLogoUploadErrorMessage(t('Unsupported file'))
      }
    }
  }, [])

  const onDropBackground = useCallback(([backgroundFile]) => {
    if (backgroundFile.size > IMAGE_MAX_SIZE) {
      setBgUploadErrorMessage(
        t('File {{fileName}} is too large. Maximum size is 5 MB', {
          fileName: backgroundFile.name,
        })
      )
    } else {
      if (checkSupportFile(backgroundFile)) {
        setBgUploadErrorMessage('')
        setBackgroundLoading(true)
        mutateUploadBackground({ variables: { file: backgroundFile } })
        setPreviewBackground(
          [backgroundFile].map(file =>
            Object.assign(file, {
              preview: URL?.createObjectURL(file),
            })
          )
        )
      } else {
        setBgUploadErrorMessage(t('Unsupported file'))
      }
    }
  }, [])

  const checkSupportFile = (file: File) => {
    const isAcceptedFile = ACCEPT_FILES.some(item => file.name.endsWith(item))
    return isAcceptedFile
  }

  const removeLocalAttachment = (index: number) => {
    setFiles(files.filter((_: any, i: number) => i !== index))
  }

  const uploadEmailAttachments = useCallback(files => {
    let total = 0
    files.forEach((file: File) => {
      total += file.size
    })

    if (total > 50000000) {
      const message = t('File(s) size must be less than 50Mb')
      setAttachmentErrorMessage(message)

      setTimeout(() => {
        setAttachmentErrorMessage('')
      }, 8000)
    } else {
      if (files.length > 0) {
        setFilesLoading(true)
        mutateUploadFiles({ variables: { files } })
      }
    }
  }, [])

  const handleSetFormData = (values: any) => {
    const eventFormData = {
      ...values,
      currency: values?.currency ? values?.currency?.value : null,
      capacity: values.capacity ? values.capacity : null,
      hotelBookingUrl: values.hotelBookingUrl ? values.hotelBookingUrl : '',
      eventSetting: {
        ...values.eventSetting,
        backgroundPicture: fileBackground.uri,
        primaryColor: color.hex,
        fontColor: textColor.hex,
        bgColor: bgColor.hex,
        logo: fileLogo.uri,
        backgroundTransparent: String(bgOpacity / 100),
        bgColorOpacity: String(bgColorOpacity / 100),
        logoSize: isCustomLogoSize ? logoSize : null,
        isShowPrice: values.isBookable ? values.eventSetting.isShowPrice : true,
        layoutType: layoutType,
      },
      price: values.isBookable ? Number(values.price) : 0,
      isBookable: values.isBookable ? values.isBookable : false,
      customFields: formData.customFields ? formData.customFields : [],
    }

    if (
      eventFormData.capacity &&
      formData.attendeesCount &&
      eventFormData.capacity < formData.attendeesCount
    ) {
      const message = t(
        'The capacity is lower than the number of existing attendees. Total attendees: {{count}}',
        { count: formData?.attendeesCount }
      )
      return toast({
        title: t('Error'),
        description: t(message),
        variant: 'destructive',
      })
    }

    return eventFormData
  }

  const handleSetPreviewValues = (values: any) => {
    const eventFormData = {
      ...values,
      currency: values?.currency ? values?.currency?.value : null,
      capacity: values.capacity ? values.capacity : null,
      hotelBookingUrl: values.hotelBookingUrl ? values.hotelBookingUrl : '',
      address: {
        ...values?.address,
        country: values?.address?.country?.value,
      },
      eventSetting: {
        ...values.eventSetting,
        backgroundPicture: fileBackground.uri,
        primaryColor: color.hex,
        fontColor: textColor.hex,
        bgColor: bgColor.hex,
        logo: fileLogo.uri,
        backgroundTransparent: String(bgOpacity / 100),
        bgColorOpacity: String(bgColorOpacity / 100),
        logoSize: isCustomLogoSize ? logoSize : null,
        isShowPrice: values.isBookable ? values.eventSetting.isShowPrice : true,
        layoutType: layoutType,
      },
      price: values.isBookable ? Number(values.price) : 0,
      isBookable: values.isBookable ? values.isBookable : false,
      customFields: formData.customFields ? formData.customFields : [],
    }

    return eventFormData
  }

  const handleGoBack = (values: any) => {
    const eventFormData = handleSetFormData(values)
    dispatch(setFormData(eventFormData))
    dispatch(setStep(step - 1))
  }

  return (
    <div className='mx-auto p-6 bg-white'>
      <div className='mb-8 flex justify-between'>
        <div>
          <h3 className='text-2xl font-bold text-gray-800'>
            {t('Event Settings')}
          </h3>
          <p className='text-sm text-gray-600 mt-2'>
            {t('Customize event settings.')}
          </p>
        </div>
        <Button
          variant='outline'
          className='mt-4'
          onClick={() => setOpenPreview(true)}
        >
          <ScanEye className='w-4 h-4 mr-2' />
          {t('event_setting.setting_preview.title')}
        </Button>
      </div>
      <Separator className='my-6' />
      <div className='grid grid-cols-1 md:grid-cols-2 gap-8'>
        <div className='space-y-6'>
          <div className='bg-gray-50 p-6 rounded-lg'>
            <h4 className='text-lg font-semibold mb-4'>
              {t('Upload Event Logo')}
            </h4>
            <EventUploadFile
              setFile={setFileLogo}
              onDrop={onDropLogo}
              fileUri={fileLogo.uri}
              isLoading={logoLoading}
              filePreview={previewLogo}
              isResizable={isCustomLogoSize}
              logoSize={logoSize}
              setLogoSize={setLogoSize}
              setPreview={setPreviewLogo}
              errorMessage={logoUploadErrorMessage}
            >
              {logoLoading && <FileDotsLoading />}
            </EventUploadFile>
            <div className='flex items-center space-x-2 mt-4'>
              <FormLabel className='text-sm font-medium'>
                {t('Custom logo size')}?
              </FormLabel>
              <Switch
                checked={isCustomLogoSize}
                onCheckedChange={value => setIsCustomLogoSize(value)}
              />
            </div>
            {isCustomLogoSize && (
              <FormLabel className='text-sm text-gray-600 mt-2'>
                {t('Click the logo to custom size')}
              </FormLabel>
            )}
          </div>

          <div className='bg-gray-50 p-6 rounded-lg'>
            <h4 className='text-lg font-semibold mb-4'>
              {t('event_setting.background_settings.title')}
            </h4>
            <p className='text-sm font-bold mb-2'>{t('Upload Event Image')}</p>
            <EventUploadFile
              setFile={setFileBackground}
              onDrop={onDropBackground}
              fileUri={fileBackground.uri}
              isLoading={backgroundLoading}
              filePreview={previewBackground}
              setPreview={setPreviewBackground}
              bgOpacity={bgOpacity}
              errorMessage={bgUploadErrorMessage}
            >
              {backgroundLoading && <FileDotsLoading />}
            </EventUploadFile>
            <div className='mt-4'>
              <FormLabel className='text-sm font-medium mb-2 block'>
                <HelpText
                  text={`${t('Event Image Opacity')} ${bgOpacity + '%'}`}
                  helpMessage={t(
                    'event_setting.image_settings.image_opacity_help_text'
                  )}
                />
              </FormLabel>
              <Slider
                defaultValue={[bgOpacity]}
                onValueChange={values => setBgOpacity(values[0])}
                max={100}
                step={1}
                className='max-w-[400px]'
              />
            </div>
            <div className='mt-4'>
              <FormItem>
                <FormLabel>
                  <HelpText
                    text={t('event_setting.layout_type.title')}
                    helpMessage={t('event_setting.layout_type.description')}
                  />
                </FormLabel>
                <RadioGroup
                  onValueChange={(value: EventLayoutType) => {
                    setLayoutType(value)
                  }}
                  value={layoutType}
                >
                  <div className='flex items-center space-x-2'>
                    <RadioGroupItem
                      value={EventLayoutType.CLASSIC}
                      id={EventLayoutType.CLASSIC}
                    />
                    <Label htmlFor={EventLayoutType.CLASSIC}>
                      {t('event_setting.layout_type.classic')}
                    </Label>
                  </div>
                  <div className='flex items-center space-x-2'>
                    <RadioGroupItem
                      value={EventLayoutType.TWO_SIDED}
                      id={EventLayoutType.TWO_SIDED}
                    />
                    <Label htmlFor={EventLayoutType.TWO_SIDED}>
                      {t('event_setting.layout_type.two_sided')}
                    </Label>
                  </div>
                </RadioGroup>
              </FormItem>
            </div>

            <div className='mt-4 grid grid-cols-2 gap-4'>
              <FormItem>
                <FormLabel>
                  <HelpText
                    text={t(
                      'event_setting.background_settings.background_color'
                    )}
                    helpMessage={t(
                      'event_setting.background_settings.description'
                    )}
                  />
                </FormLabel>
                <div
                  style={{
                    background: `${bgColor.hex}`,
                    opacity: bgColorOpacity / 100,
                  }}
                  onClick={() =>
                    setToggleBackgroundColorPicker(!toggleBackgroundColorPicker)
                  }
                  className='h-10 w-full rounded-md cursor-pointer border'
                />
                <Popover
                  open={toggleBackgroundColorPicker}
                  onOpenChange={setToggleBackgroundColorPicker}
                >
                  <PopoverTrigger asChild>
                    <Button
                      variant='outline'
                      className='w-full justify-start text-left font-normal'
                    >
                      <div
                        className='mr-2 h-4 w-4 rounded'
                        style={{
                          backgroundColor: bgColor.hex,
                        }}
                      />
                      {bgColor.hex}
                    </Button>
                  </PopoverTrigger>
                  <PopoverContent className='w-[280px]'>
                    <ColorPicker
                      width={300}
                      height={120}
                      color={bgColor}
                      onChange={(color: any) => {
                        setBgColor(color)
                      }}
                      hideHSV
                      hideRGB
                      dark
                    />
                  </PopoverContent>
                </Popover>
              </FormItem>
              <FormItem>
                <FormLabel className='text-sm font-medium mb-2 block'>
                  <HelpText
                    text={`${t('Background Color Opacity')} ${bgColorOpacity}%`}
                    helpMessage={t(
                      'event_setting.background_settings.opacity_help_text'
                    )}
                  />
                </FormLabel>
                <Slider
                  defaultValue={[bgColorOpacity]}
                  onValueChange={values => setBgColorOpacity(values[0])}
                  max={100}
                  step={1}
                  className='max-w-[400px]'
                />
              </FormItem>
            </div>
          </div>
        </div>

        <div className='space-y-6'>
          <Form
            onSubmit={onSubmit}
            initialValues={{
              ...formData,
              currency: {
                label: formData?.currency?.label || formData?.currency,
                value: formData?.currency?.value || formData?.currency,
              },
              // slug: formData?.slug || slugify(formData?.title || ''),
            }}
            keepDirtyOnReinitialize={true}
            render={({
              handleSubmit,
              values,
              submitting,
              form: { getState },
            }: any) => (
              <form onSubmit={handleSubmit} className='space-y-6'>
                <div className='bg-gray-50 p-6 rounded-lg'>
                  <h4 className='text-lg font-semibold mb-4'>
                    {t('event_setting.color_settings.title')}
                  </h4>
                  <div className='grid grid-cols-2 gap-4'>
                    <FormItem>
                      <FormLabel>
                        <HelpText
                          text={t('Dominant Color')}
                          helpMessage={t(
                            'Dominant color of the event (header, footer, button, etc. background)'
                          )}
                        />
                      </FormLabel>
                      <div
                        style={{
                          background: `${color.hex}`,
                        }}
                        onClick={() => setToggleColorPicker(!toggleColorPicker)}
                        className='h-10 w-full rounded-md cursor-pointer'
                      />
                      <Popover
                        open={toggleColorPicker}
                        onOpenChange={setToggleColorPicker}
                      >
                        <PopoverTrigger asChild>
                          <Button
                            variant='outline'
                            className='w-full justify-start text-left font-normal'
                          >
                            <div
                              className='mr-2 h-4 w-4 rounded'
                              style={{ backgroundColor: color.hex }}
                            />
                            {color.hex}
                          </Button>
                        </PopoverTrigger>
                        <PopoverContent className='w-[280px]'>
                          <ColorPicker
                            width={300}
                            height={120}
                            color={color}
                            onChange={(color: any) => {
                              setColor(color)
                            }}
                            hideHSV
                            hideRGB
                            dark
                          />
                        </PopoverContent>
                      </Popover>
                    </FormItem>
                    <FormItem>
                      <FormLabel>
                        <HelpText
                          text={t('Text Color')}
                          helpMessage={t('Text color of the event')}
                        />
                      </FormLabel>
                      <div
                        style={{
                          background: `${textColor.hex}`,
                        }}
                        onClick={() =>
                          setToggleTextColorPicker(!toggleTextColorPicker)
                        }
                        className='h-10 w-full rounded-md cursor-pointer'
                      />
                      <Popover
                        open={toggleTextColorPicker}
                        onOpenChange={setToggleTextColorPicker}
                      >
                        <PopoverTrigger asChild>
                          <Button
                            variant='outline'
                            className='w-full justify-start text-left font-normal'
                          >
                            <div
                              className='mr-2 h-4 w-4 rounded'
                              style={{
                                backgroundColor: textColor.hex,
                              }}
                            />
                            {textColor.hex}
                          </Button>
                        </PopoverTrigger>
                        <PopoverContent className='w-[280px]'>
                          <ColorPicker
                            width={300}
                            height={120}
                            color={textColor}
                            onChange={(color: any) => {
                              setTextColor(color)
                            }}
                            hideHSV
                            hideRGB
                            dark
                          />
                        </PopoverContent>
                      </Popover>
                    </FormItem>
                  </div>
                </div>

                <div className='space-y-6 bg-gray-50 p-6 rounded-lg'>
                  <div className='flex items-center justify-between'>
                    <FormItem>
                      <FormLabel>
                        <HelpText
                          text={t('Booking with Payment?')}
                          helpMessage={t('Enable payment for the event')}
                        />
                      </FormLabel>
                      <Field
                        name='isBookable'
                        component={RenderSwitch}
                        defaultValue={false}
                      />
                    </FormItem>
                  </div>
                  {values.isBookable && (
                    <div className='flex items-center space-x-4 justify-between'>
                      <FormItem>
                        <FormLabel>
                          <HelpText
                            text={t('Show price?')}
                            helpMessage={t(
                              'Enable to show price on the event detail page'
                            )}
                          />
                        </FormLabel>
                        <Field
                          name='eventSetting.isShowPrice'
                          component={RenderSwitch}
                          defaultValue={true}
                        />
                      </FormItem>
                      <Field
                        name='price'
                        defaultValue={0}
                        validate={requiredPrice}
                        parse={parse}
                        format={formatPrice}
                      >
                        {({ input, meta }: any) => (
                          <FormItem>
                            <FormLabel meta={meta} isRequired>
                              {t('Price per person')}
                            </FormLabel>
                            <Input
                              {...input}
                              meta={meta}
                              isRequired
                              type='text'
                              onKeyDown={(e: any) => {
                                blockTypingNonNumberic(e)
                              }}
                              maxLength='15'
                              className='w-full'
                            />
                          </FormItem>
                        )}
                      </Field>
                      <FormItem>
                        <FormLabel>
                          <HelpText
                            text={t('Currency')}
                            helpMessage={t('Currency of the price')}
                          />
                        </FormLabel>
                        <Field
                          name='currency'
                          component={ReactSelectAdapter}
                          placeholder={t('Currency')}
                          options={Currency}
                        />
                      </FormItem>
                    </div>
                  )}

                  <Field
                    name='slug'
                    format={trimWhiteSpace}
                    formatOnBlur
                    // validate={validateSlug}
                  >
                    {({ input, meta }: any) => (
                      <FormItem>
                        <FormLabel meta={meta}>
                          <HelpText
                            text={t('event_setting.slug.title')}
                            helpMessage={t('event_setting.slug.help_message', {
                              rootUrl: Environment.rootUrl,
                            })}
                          />
                        </FormLabel>
                        <div className='relative'>
                          <Input
                            {...input}
                            type='text'
                            meta={meta}
                            className={cn(
                              'w-full',
                              meta.error && meta.touched && 'border-red-500'
                            )}
                          />
                          {isCheckingSlug && (
                            <div className='absolute right-3 top-1/2 -translate-y-1/2'>
                              <SlugCheckingLoader />
                            </div>
                          )}
                        </div>
                        {meta.error && meta.touched && (
                          <span className='text-sm text-red-500 mt-1'>
                            {meta.error}
                          </span>
                        )}
                      </FormItem>
                    )}
                  </Field>

                  <Field
                    name='capacity'
                    format={trimZeroNumber}
                    parse={parseCapacity}
                  >
                    {({ input, meta }: any) => (
                      <FormItem>
                        <FormLabel meta={meta}>
                          <HelpText
                            text={t('Capacity')}
                            helpMessage={t('Maximum number of attendees')}
                          />
                        </FormLabel>
                        <Input {...input} type='text' className='w-full' />
                      </FormItem>
                    )}
                  </Field>

                  <div className='grid grid-cols-2 gap-4'>
                    <Field
                      name='hotelBookingUrl'
                      type='text'
                      format={trimWhiteSpace}
                      formatOnBlur
                      validate={hotelBookingURLValidation}
                    >
                      {({ input, meta }: any) => (
                        <FormItem>
                          <FormLabel meta={meta}>
                            <HelpText
                              text={t('event_admin.hotel_booking_url')}
                              helpMessage={t('URL to the hotel booking page')}
                            />
                          </FormLabel>
                          <Input {...input} meta={meta} className='w-full' />
                        </FormItem>
                      )}
                    </Field>

                    <Field
                      name='agendaUrl'
                      type='text'
                      format={trimWhiteSpace}
                      formatOnBlur
                      validate={hotelBookingURLValidation}
                    >
                      {({ input, meta }: any) => (
                        <FormItem>
                          <FormLabel meta={meta}>
                            <HelpText
                              text={t('event_admin.agenda_url')}
                              helpMessage={t('URL to the agenda page')}
                            />
                          </FormLabel>
                          <Input
                            {...input}
                            maxLength='256'
                            meta={meta}
                            className='w-full'
                          />
                        </FormItem>
                      )}
                    </Field>
                  </div>

                  <div className='space-y-4'>
                    <FormItem>
                      <FormLabel>
                        <HelpText
                          text={t('Send QR-Code after register?')}
                          helpMessage={t('Send QR-Code after registration')}
                        />
                      </FormLabel>
                      <Field
                        name='sendQrCode'
                        component={RenderSwitch}
                        defaultValue={false}
                      />
                    </FormItem>

                    <FormItem>
                      <FormLabel>{t('with Webcast integration?')}</FormLabel>
                      <Field
                        name='hasWebcastIntegration'
                        component={RenderSwitch}
                        defaultValue={!!formData.webcastEventId}
                        disabled={!!formData.webcastEventId}
                      />
                    </FormItem>

                    <FormItem>
                      <FormLabel>
                        <HelpText
                          text={t('Enable cancellation?')}
                          helpMessage={t('Enable cancellation for the event')}
                        />
                      </FormLabel>
                      <Field
                        name='eventSetting.cancellation'
                        component={RenderSwitch}
                      />
                    </FormItem>

                    <FormItem>
                      <FormLabel>
                        <HelpText
                          text={t('Create attendee account')}
                          helpMessage={t(
                            'Create attendee account after registration'
                          )}
                        />
                      </FormLabel>
                      <Field
                        name='shouldCreateAttendantUser'
                        component={RenderSwitch}
                      />
                    </FormItem>

                    <FormItem>
                      <FormLabel>
                        <HelpText
                          text={t('Lock register?')}
                          helpMessage={t('Lock the registration page')}
                        />
                      </FormLabel>
                      <Field
                        name='eventSetting.isLockedRegister'
                        component={RenderSwitch}
                      />
                    </FormItem>

                    <FormItem>
                      <FormLabel>
                        <HelpText
                          text={t('Enable Calendar Entry')}
                          helpMessage={t('Enable calendar entry for the event')}
                        />
                      </FormLabel>
                      <Field
                        name='eventSetting.isCalendarEntryEnabled'
                        component={RenderSwitch}
                      />
                    </FormItem>
                  </div>

                  <EventAttachments
                    errorMessage={attachmentErrorMessage}
                    onDrop={uploadEmailAttachments}
                    removeLocalAttachment={removeLocalAttachment}
                    uploadLoading={filesLoading}
                  />
                </div>

                <div className='flex justify-between items-center mt-8 gap-4'>
                  {matchEditRoute && (
                    <div className='w-full'>
                      <SaveChangesNowButton
                        handleGetFormValues={() => {
                          dispatch(setFormData(getState().values))
                          return handleSetFormData(getState().values)
                        }}
                        id={id}
                        disabled={
                          submitting ||
                          backgroundLoading ||
                          logoLoading ||
                          filesLoading
                        }
                      />
                    </div>
                  )}
                  <Button
                    type='submit'
                    disabled={
                      submitting ||
                      backgroundLoading ||
                      logoLoading ||
                      filesLoading
                    }
                    className='w-full'
                  >
                    {t('Continue')}
                  </Button>
                </div>

                <ChevronLeft
                  onClick={() => handleGoBack(getState().values)}
                  className={cn(
                    'absolute top-[-9px] left-14 cursor-pointer md:top-[4px] md:left-24',
                    !matchEditRoute && 'text-white left-5 md:left-8'
                  )}
                />
                <EventDetailPreview
                  open={openPreview}
                  onClose={() => setOpenPreview(false)}
                  event={handleSetPreviewValues(getState().values)}
                />
              </form>
            )}
          />
        </div>
      </div>
    </div>
  )
}

const FileDotsLoading = styled(DotsLoading)`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
`

export default EventSetting
