import { Button } from '@/components/ui/button'
import { Icons } from '@/components/ui/icon'
import { toast } from '@/components/ui/use-toast'
import { TwoFactorMethod } from '@/constants/user'
import { LOGIN_MUTATION } from '@/graphql/mutations'
import { cn } from '@/lib/utils'
import { IUser } from '@/models'
import { useUserStore } from '@/store/zustand'
import { receiveMessage, use2FAStore, verifyCode } from '@/store/zustand/TowFA'
import { useHistory, useLocation, useMutation } from '@/utils/adapters'
import { useAppDispatch } from '@/utils/hooks'
import { zodResolver } from '@hookform/resolvers/zod'
import React, { FC, useState, useEffect } from 'react'
import { useForm } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { setLoggedIn } from 'store/Authentication'
import * as z from 'zod'
import { TwoFactorAuthCode } from '../../OwnAccount/components/TwoFactorAuthen/TwoFactorAuthCode'
import { EMAIL_REGEX, MAX_LENGTH } from '@/constants/regex'
import { MESSAGE } from '@/constants/message'
import ForceTwoFAForm from '../../OwnAccount/components/TwoFactorAuthen/ForceTwoFAForm'
import { encryptArray } from '@/utils/helpers/role'
import { LoginForm } from './LoginForm'
import { ForgotPasswordRequestForm } from './ForgotPasswordRequestForm'
import { REQUEST_RESET_PASSWORD } from '@/graphql/mutations'
import { ResetPasswordForm } from './ResetPasswordForm'

interface UserAuthFormProps extends React.HTMLAttributes<HTMLDivElement> {
  onPageChange: (page: 'login' | 'forgotPassword' | 'resetPassword') => void
}

export const UserAuthForm: FC<UserAuthFormProps> = ({
  className,
  onPageChange,
  ...props
}) => {
  const { t } = useTranslation()
  // const { state } = useLocation()
  const dispatch = useAppDispatch()
  const history = useHistory()
  const location = useLocation()
  const { currentUser, setCurrentUser, isTwoFaAuthenticated } = useUserStore()
  const [isLoading, setIsLoading] = useState<boolean>(false)
  const [is2FAEnabled, setIs2FAEnabled] = useState<boolean>(false)
  const [isForce2FAEnabled, setIsForce2FAEnabled] = useState<boolean>(false)
  const [otpValue, setOtpValue] = useState<string>('')
  const [destination, setDestination] = useState<string>('')
  const [isForgotPassword, setIsForgotPassword] = useState<boolean>(false)
  const [isForgotPasswordLoading, setIsForgotPasswordLoading] =
    useState<boolean>(false)
  const [isResetPassword, setIsResetPassword] = useState<boolean>(false)
  const [resetToken, setResetToken] = useState<string | null>(null)

  useEffect(() => {
    const path = location.pathname
    const resetPasswordRegex = /^\/reset\/(.+)$/
    const match = path.match(resetPasswordRegex)

    if (match) {
      setIsResetPassword(true)
      setResetToken(match[1])
      onPageChange('resetPassword')
    } else {
      setIsResetPassword(false)
      setResetToken(null)
      onPageChange('login')
    }
  }, [location.pathname, onPageChange])

  const formSchema = z.object({
    email: z
      .string()
      .min(1, { message: t(MESSAGE.REQUIRED_FIELD) })
      .max(MAX_LENGTH.COMMON_FIELD, {
        message: t(MESSAGE.TOO_LONG, {
          max: MAX_LENGTH.COMMON_FIELD,
        }),
      })
      .regex(EMAIL_REGEX, { message: t(MESSAGE.INVALID_EMAIL) }),
    password: z.string().min(1, { message: t(MESSAGE.REQUIRED_FIELD) }),
  })

  type FormValues = z.infer<typeof formSchema>

  const defaultValues: Partial<FormValues> = {
    email: '',
    password: '',
  }

  const form = useForm<FormValues>({
    resolver: zodResolver(formSchema),
    defaultValues,
    mode: 'onChange',
  })

  async function onSubmit(values: any) {
    setIsLoading(true)
    onLogin({ variables: { user: values } })
  }

  const handleDestination = (
    method: TwoFactorMethod,
    email: string,
    phoneNumber: string
  ): string => {
    switch (method.toLowerCase()) {
      case TwoFactorMethod.PHONE:
      case TwoFactorMethod.SMS:
        return `${t('Phone ending in ')}${phoneNumber}`
      default:
        return ''
    }
  }

  const [onLogin, { loading }] = useMutation(LOGIN_MUTATION, {
    onCompleted({ login }) {
      if (login) {
        localStorage.setItem('ACCESS_TOKEN', login.accessToken as string)
        localStorage.setItem('REFRESH_TOKEN', login.refreshToken as string)
        const {
          is2FAEnabled,
          organization,
          twoFactorMethod,
          email,
          phoneNumber,
          _id,
          firstName,
          lastName,
          company,
          roles,
          scopes,
          userName,
        } = login
        const user: IUser = {
          _id: _id,
          firstName: firstName,
          lastName: lastName,
          company: company,
          email: email,
          roles: roles,
          scopes: scopes,
          userName: userName,
          // isRemoved: isRemoved,
          phoneNumber: phoneNumber,
          organization: organization,
          is2FAEnabled: is2FAEnabled,
          twoFactorMethod: twoFactorMethod,
        }
        const encryptedUser = {
          ...user,
          roles: encryptArray(user.roles),
          scopes: encryptArray(user.scopes),
        }

        sessionStorage.setItem('user', JSON.stringify(encryptedUser))
        setCurrentUser(user)
        // if (state) {
        //   if (!login.is2FAEnabled) {
        //     history.push((state as PathType)?.from)
        //   } else {
        //     setIs2FAEnabled(true)
        //     setDestination(
        //       handleDestination(
        //         login.twoFactorMethod,
        //         login.email,
        //         login.phoneNumber
        //       )
        //     )
        //     user?.twoFactorMethod &&
        //       receiveMessage(TwoFactorMethod[user?.twoFactorMethod])
        //     setIsLoading(false)
        //     dispatch(setLoggedIn(true))
        //   }
        // } else {

        const isForced2FA = organization.force2FAEnabled
        // Check if 2FA is not enabled for the user and not forced 2FA by the organization
        if (!is2FAEnabled && !isForced2FA) {
          handleSuccessfulLogin()
          return
        }
        // Check if 2FA is enabled for the user
        if (is2FAEnabled) {
          setIs2FAEnabled(true)
          setDestination(handleDestination(twoFactorMethod, email, phoneNumber))
          setIsLoading(false)

          if (user?.twoFactorMethod) {
            receiveMessage(TwoFactorMethod[user.twoFactorMethod])
          }
          return
        }
        // Check if 2FA is not enabled for the user and forced 2FA by the organization
        if (!is2FAEnabled && isForced2FA) {
          setIsForce2FAEnabled(true)
          // setIs2FAEnabled(true)
          // setDestination(handleDestination(twoFactorMethod, email, phoneNumber))
          // setIsLoading(false)

          // if (user?.twoFactorMethod) {
          //   receiveMessage(TwoFactorMethod[user.twoFactorMethod])
          // }
          // return
          // dispatch(setLoggedIn(true))
          // history.push('/')
          // return
        }
      }
      // }
    },
    onError: err => {
      err.graphQLErrors.map(({ message }) => {
        toast({
          variant: 'destructive',
          title: t('Login failed'),
          description: t(message),
        })
        setIsLoading(false)
      })
    },
  })

  const handleTwoFactorCode = async () => {
    setIsLoading(true)
    const res: any = await verifyCode(otpValue)
    if (res) {
      const { data } = res
      localStorage.setItem('ACCESS_TOKEN', data?.accessToken as string)
      localStorage.setItem('REFRESH_TOKEN', data?.refreshToken as string)
      handleSuccessfulLogin()
    } else {
      toast({
        variant: 'destructive',
        title: t('Login failed'),
        description: t(use2FAStore.getState().errorMessage),
      })
    }
    setIsLoading(false)
  }

  const handleBackVerifyTwoFA = () => {
    setIsLoading(false)
    setIsForce2FAEnabled(false)
  }

  const [mutateRequestResetPassword] = useMutation(REQUEST_RESET_PASSWORD, {
    onCompleted: data => {
      if (data) {
        toast({
          title: t('Success'),
          description: t('Password reset email sent'),
        })
        setIsForgotPassword(false)
      }
      setIsForgotPasswordLoading(false)
    },
    onError: err => {
      toast({
        title: t('Error'),
        description: t(err.message),
        variant: 'destructive',
      })
      setIsForgotPasswordLoading(false)
    },
  })

  const onForgotPasswordSubmit = (values: { email: string }) => {
    setIsForgotPasswordLoading(true)
    mutateRequestResetPassword({
      variables: {
        email: values.email,
      },
    }).then(() => {
      onPageChange('login')
    })
  }

  const handleSuccessfulLogin = () => {
    const redirectUrl = sessionStorage.getItem('redirectUrl')
    sessionStorage.removeItem('redirectUrl') // Clear it after use
    
    dispatch(setLoggedIn(true))
    if (redirectUrl) {
      history.push(redirectUrl)
    } else {
      history.push('/')
    }
  }

  const renderAuthContent = () => {
    if (is2FAEnabled) {
      return (
        <div className='grid space-y-8'>
          <TwoFactorAuthCode
            destination={destination}
            otpValue={otpValue}
            setOtpValue={setOtpValue}
          />
          <div className='grid grid-cols-3 grid-flow-col space-x-4'>
            <Button
              variant='outline'
              disabled={isLoading || loading}
              onClick={() => {
                setIs2FAEnabled(false)
                setOtpValue('')
                useUserStore.setState(() => ({
                  isTwoFaAuthenticated: true,
                }))
              }}
            >
              {t('Back')}
            </Button>
            <Button
              disabled={isLoading || loading}
              className='col-span-2'
              onClick={handleTwoFactorCode}
            >
              {(isLoading || loading) && (
                <Icons.spinner className='mr-2 h-4 w-4 animate-spin' />
              )}
              {t('Verify')}
            </Button>
          </div>
        </div>
      )
    }

    if (isForce2FAEnabled) {
      return (
        <ForceTwoFAForm
          handleBack={handleBackVerifyTwoFA}
          email={form.getValues().email}
        />
      )
    }

    if (isForgotPassword) {
      return (
        <ForgotPasswordRequestForm
          siteKey={import.meta.env.VITE_TURNSTILE_SITE_KEY}
          onSubmit={onForgotPasswordSubmit}
          onBackToLogin={() => {
            setIsForgotPassword(false)
            onPageChange('login')
          }}
          isLoading={isForgotPasswordLoading}
        />
      )
    }

    if (isResetPassword && resetToken) {
      return (
        <ResetPasswordForm
          token={resetToken}
          onBackToLogin={() => {
            setIsResetPassword(false)
            history.push('/login')
            onPageChange('login')
          }}
        />
      )
    }

    return (
      <LoginForm
        onSubmit={onSubmit}
        onForgotPasswordClick={() => {
          setIsForgotPassword(true)
          onPageChange('forgotPassword')
        }}
        isLoading={isLoading}
      />
    )
  }

  return (
    <div className={cn('grid gap-6', className)} {...props}>
      {renderAuthContent()}
      <div className='relative'>
        <div className='absolute inset-0 flex items-center'>
          <span className='w-full border-t' />
        </div>
      </div>
    </div>
  )
}
