import {
  ApolloClient,
  ApolloLink,
  fromPromise,
  InMemoryCache,
  NormalizedCacheObject,
} from '@apollo/client'
import { t } from 'i18next'

import { setContext } from '@apollo/client/link/context'
import { createUploadLink } from 'apollo-upload-client'
import { onError } from '@apollo/client/link/error'
import axios from 'axios'

import { Environment } from 'environments'
import { store } from 'store'
import { setLoggedIn } from 'store/Authentication'
import { ERROR_CODE } from 'constants/message'
import { omitTypenameLink } from 'utils/helpers'
import { toast } from '@/components/ui/use-toast'
import { PATH_NAME } from 'routes/routesMap'

const uploadLink = createUploadLink({ uri: `${Environment.serverUrl}/graphql` })

const authLink = setContext(async (_, { headers }) => {
  const token = localStorage.getItem('ACCESS_TOKEN')
  if (token) {
    return {
      headers: {
        ...headers,
        authorization: token ? `Bearer ${token}` : '',
      },
    }
  } else {
    return {
      headers: {
        ...headers,
      },
    }
  }
})

const getRefreshToken = async () => {
  const promise = await axios.post(
    `${Environment.serverUrl}/graphql`,
    {
      query: `
          mutation FetchToken {
            fetchToken {
              accessToken
            }
          }
        `,
    },
    {
      headers: {
        refresh_token: localStorage.getItem('REFRESH_TOKEN'),
      },
    }
  )
  const accessToken = promise.data.data.fetchToken.accessToken
  return accessToken
}

let isRefreshing = false
let pendingRequests: any = []

const resolvePendingRequests = () => {
  pendingRequests.map((callback: () => any) => callback())
  pendingRequests = []
}

const errorLink = onError(
  ({ graphQLErrors, operation, forward, response }: any) => {
    if (graphQLErrors) {
      for (const err of graphQLErrors) {
        if (err?.code === 'UNAUTHORIZED') {
          const currentPath = window.location.pathname + window.location.search
          if (currentPath !== PATH_NAME.LOGIN) {
            sessionStorage.setItem('redirectUrl', currentPath)
          }

          let forward$
          if (!isRefreshing) {
            isRefreshing = true
            forward$ = fromPromise(
              getRefreshToken()
                .then(accessToken => {
                  resolvePendingRequests()
                  localStorage.setItem('ACCESS_TOKEN', accessToken)
                  return true
                })
                .catch(() => {
                  pendingRequests = []
                  localStorage.clear()
                  sessionStorage.clear()
                  client.clearStore()
                  store.dispatch(setLoggedIn(false))
                  window.location.replace('/login')
                  return false
                })
                .finally(() => {
                  isRefreshing = false
                })
            ).filter(value => Boolean(value))
          } else {
            forward$ = fromPromise(
              new Promise<void>(resolve => {
                pendingRequests.push(() => resolve())
              })
            )
          }
          return forward$.flatMap(() => forward(operation))
        } else if (err?.code === 'PERMISSION_DENIED') {
          sessionStorage.removeItem('redirectUrl')
          window.location.replace('/')
          toast({
            title: t('Error'),
            description: t(err.message),
            variant: 'destructive',
          })
          response.errors = null
        } else if (err?.code === ERROR_CODE.ACCOUNT_DEACTIVATED) {
          store.dispatch(setLoggedIn(false))
          toast({
            title: t('Error'),
            description: t(err.message),
            variant: 'destructive',
          })
          localStorage.clear()
          sessionStorage.clear()
          response.errors = null
        }
      }
    }
  }
)

const client: ApolloClient<NormalizedCacheObject> = new ApolloClient({
  uri: `${Environment.serverUrl}/graphql`,
  cache: new InMemoryCache({
    // addTypename: false,
    typePolicies: {
      Query: {
        fields: {
          events: {
            merge: false,
            // merge(existing = [], incoming) {
            //   return [...existing, ...incoming]
            // }
          },
          event: {
            read(_, { args, toReference }) {
              return toReference({
                __typename: 'Event',
                id: args?.id,
              })
            },
          },
        },
      },
    },
  }),
  link: ApolloLink.from([
    omitTypenameLink,
    errorLink as unknown as ApolloLink,
    authLink,
    uploadLink as unknown as ApolloLink,
  ]),
})

export default client
