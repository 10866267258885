export const EMAIL_REGEX = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/

// only digital and - character
export const ZIP_CODE_REGEX = /^(?:[\d-]*)?$/
export const PHONE_NUMBER_REGEX = /^(?:[\d\s-]*)?$/

// Minimum eight characters, at least one letter, one number and one special character
export const PASSWORD_REGEX =
  /^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9])(?=.*?[#?!@$%^&*-]).{8,}$/

export const MAX_LENGTH = {
  COMMON_FIELD: 255,
  MAX_DESCRIPTION_SIZE: 999999,
  PASSWORD: 150,
}

export const PRICE_REGEX = /^\d{0,8}(\.\d{1,4})?$/

export const HOTEL_BOOKING_URL_REGEX = /^(https:\/\/)/

export const CURRENCY_REGEX = /^\d*[.,]?\d*$/
