import BasicTable from '@/components/organisms/BasicTable'
import { Button } from '@/components/ui/button'
import React, { FC, useMemo, useRef, useState } from 'react'
import { useTranslation } from 'react-i18next'

import { GET_EVENT_ATTENDEES } from 'graphql/queries'

import { Environment } from '@/environments'
import { ColumnDef } from '@tanstack/react-table'
import { Canvg } from 'canvg'
import Loading from 'components/atoms/Loading'
import { saveAs } from 'file-saver'
import JSZip from 'jszip'
import { FileDown } from 'lucide-react'
import ReactDOMServer from 'react-dom/server'
import QRCode from 'react-qr-code'
import { useParams, useQuery } from 'utils/adapters'
import { Icons } from '@/components/ui/icon'
import { useAppSelector } from '@/utils/hooks'
import {
  getAttendeeSystemField,
  getSystemFieldAccessor,
} from '@/lib/utils/attendeeFields'
import { SystemFieldType } from '@/constants/systemField'

const QRManagement: FC = () => {
  const { t } = useTranslation()

  const { id } = useParams<{ id: string }>()
  const lang = localStorage.getItem('i18nextLng') as string

  const [selectedRows, setSelectedRows] = useState({})
  const qrRef = useRef<any[]>([])
  const [isDownloading, setIsDownloading] = useState(false)

  const eventDetail = useAppSelector(state => state.eventsReducer.eventDetail)

  const { data: eventAttendees, loading: eventAttendeesLoading } = useQuery(
    GET_EVENT_ATTENDEES,
    {
      variables: {
        eventId: id,
      },
    }
  )
  const attendees = eventAttendees?.getEventAttendees.data

  const downloadSingleQRCode = async (idx: number) => {
    const svg = qrRef.current[idx]?.el
    const name = qrRef.current[idx]?.name
    if (svg) {
      const pngDataUrl = await svgToPng(svg)
      const pngBlob = await (await fetch(pngDataUrl)).blob()
      saveAs(pngBlob, `${name}.png`)
    }
  }

  const getSvgName = (attendee: any) => {
    const firstName = getAttendeeSystemField(
      attendee,
      eventDetail,
      SystemFieldType.FirstName,
      'firstName'
    )
    const lastName = getAttendeeSystemField(
      attendee,
      eventDetail,
      SystemFieldType.LastName,
      'lastName'
    )

    if (firstName && lastName) {
      return `${firstName}-${lastName}`
    }
    return firstName || lastName || attendee._id
  }

  const createQRCodeSVGElement = (value: string, size = 128) => {
    const qrCodeProps = {
      value: value,
      size: size,
      fgColor: '#000000',
      bgColor: '#ffffff',
    }

    // Create a React element for the QRCode component
    const qrCodeElement = React.createElement(QRCode, qrCodeProps)

    // Render the QRCode component to a string
    const svgString = ReactDOMServer.renderToString(qrCodeElement)

    // Create an SVG element from the rendered string
    const svgElement = new DOMParser().parseFromString(
      svgString,
      'image/svg+xml'
    ).documentElement

    return svgElement
  }

  const downloadAllAsZip = async attendeesList => {
    setIsDownloading(true)
    const zip = new JSZip()

    for (let i = 0; i < attendeesList.length; i++) {
      const link = `${Environment.rootUrl}/events/${id}/qr/attendees/${attendeesList[i]._id}`
      const qrCodeElement = createQRCodeSVGElement(link, 128)
      const pngDataUrl = await svgToPng(qrCodeElement)
      const pngBlob = await (await fetch(pngDataUrl)).blob()
      zip.file(
        `${i + 1}-${attendeesList[i].firstName}-${
          attendeesList[i].lastName
        }.png`,
        pngBlob
      )
    }
    const content = await zip.generateAsync({ type: 'blob' })
    setIsDownloading(false)
    const fileName = eventDetail?.title
      ?.trim()
      .toLocaleLowerCase()
      .replace(/ /g, '-')
    saveAs(content, `qr-${fileName}.zip`)
  }

  const svgToPng = async (svgElement: any) => {
    const canvas = document.createElement('canvas')
    const context = canvas.getContext('2d')
    if (!context) {
      throw new Error('Canvas context could not be obtained')
    }
    const svgData = new XMLSerializer().serializeToString(svgElement)
    const canvgInstance = Canvg.fromString(context, svgData)
    await canvgInstance.render()
    return canvas.toDataURL('image/png')
  }

  const columns = useMemo<ColumnDef<any, any>[]>(
    () => [
      {
        id: 'select',
        header: ({ table }) => (
          <input
            type='checkbox'
            checked={table.getIsAllRowsSelected()}
            onChange={table.getToggleAllRowsSelectedHandler()}
            className='w-4 h-4 text-blue-600 bg-primary rounded focus:bg-primary dark:focus:bg-primary'
          />
        ),
        cell: ({ row }) => {
          return (
            <input
              type='checkbox'
              className='w-4 h-4 text-blue-600 bg-primary rounded focus:bg-primary dark:focus:bg-primary'
              checked={row.getIsSelected()}
              onChange={row.getToggleSelectedHandler()}
            />
          )
        },
      },
      {
        header: t('No'),
        cell: ({ row }) => <b>{row?.index + 1}</b>,
      },
      {
        header: t('Email'),
        accessorKey: 'email',
      },
      {
        header: t('First Name'),
        accessorFn: getSystemFieldAccessor(
          eventDetail,
          SystemFieldType.FirstName,
          'firstName'
        ),
      },
      {
        header: t('Last Name'),
        accessorFn: getSystemFieldAccessor(
          eventDetail,
          SystemFieldType.LastName,
          'lastName'
        ),
      },
      {
        header: t('City'),
        accessorKey: 'address.city',
      },
      {
        header: t('QR'),
        cell: ({ row }) => {
          const qrValue = `${Environment.rootUrl}/events/${id}/qr/attendees/${row.original._id}`

          return (
            <>
              <div className='hidden'>
                <QRCode
                  ref={el =>
                    (qrRef.current[row.id] = {
                      el,
                      name: getSvgName(row?.original),
                    })
                  }
                  value={qrValue}
                  viewBox={'0 0 256 256'}
                />
              </div>
              <QRCode value={qrValue} viewBox={'0 0 256 256'} size={64} />
            </>
          )
        },
      },
      {
        header: t('Action'),
        cell: ({ row }) => {
          return (
            <div>
              <Button
                onClick={() => downloadSingleQRCode(row.index)}
                variant='outline'
                size='icon'
                className='hover:bg-primary hover:text-primary-foreground transition-colors'
              >
                <FileDown className='h-4 w-4' />
                <span className='sr-only'>{t('Download QR Code')}</span>
              </Button>
            </div>
          )
        },
      },
    ],
    [lang]
  )

  const handleRowSelectionChange = newSelectedRows => {
    setSelectedRows(newSelectedRows)
  }

  const handleGetSelectedAttendees = (count: number) => {
    if (count > 0) {
      const selectedAttendees = Object.keys(selectedRows)
        .filter(rowId => selectedRows[rowId])
        .map(rowId => eventAttendees.getEventAttendees.data[rowId])
      downloadAllAsZip(selectedAttendees)
    } else {
      downloadAllAsZip(attendees)
    }
  }

  if (eventAttendeesLoading) {
    return <Loading />
  }

  return (
    <div className='h-full flex-1 flex-col space-y-8 p-4 md:p-8 md:flex'>
      <div className='flex flex-col sm:items-center justify-between space-y-2 sm:flex-row'>
        <h2 className='text-2xl font-bold tracking-tight'>{t('qr.title')}</h2>
        <Button
          disabled={isDownloading}
          onClick={() =>
            handleGetSelectedAttendees(Object.values(selectedRows).length)
          }
        >
          {isDownloading && (
            <Icons.spinner className='mr-2 h-4 w-4 animate-spin' />
          )}
          {t('qr.download_btn', {
            number:
              Object.values(selectedRows).length > 0
                ? Object.values(selectedRows).length
                : t('qr.all'),
          })}
        </Button>
      </div>
      <BasicTable
        columns={columns}
        data={eventAttendees.getEventAttendees.data}
        selectedRows={selectedRows}
        onRowSelectionChange={handleRowSelectionChange}
        loading={eventAttendeesLoading}
        isPaginationByClient
        isGlobalSearchable
        globalSearchPlaceholder={t('Search attendee...')}
        totalItems={eventAttendees.getEventAttendees.data.length}
      />
    </div>
  )
}

export default QRManagement
