import Loading from 'components/atoms/Loading'
import { FC, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useAdminEventDetail } from './mixins'

import { BadgeCurrency } from '@/components/atoms/BadgeCurrency'
import CopyToClipBoard from '@/components/molecules/CopyToClipBoard/CopyToClipBoard'
import { COLORS } from '@/constants/colors'
import { EventLayoutType, EventType } from '@/constants/events'
import { Environment } from '@/environments'
import { cn } from '@/lib/utils'
import { hexToRgb, setTextColorBasedOnBackground } from '@/utils/helpers/colors'
import { Background } from 'assets/images'
import DOMPurify from 'dompurify'
import { CalendarDays, Clock, MapPin } from 'lucide-react'
import { useParams } from 'utils/adapters'
import {
  checkCapacity,
  defaultTimezone,
  getEventDateByTimeZone,
  getEventTime,
  priceFormat,
  transformAddress,
} from 'utils/helpers'

const AdminEventDetail: FC = () => {
  const { id } = useParams<{ id: string }>()
  const { data, loading } = useAdminEventDetail(id)

  const { t } = useTranslation()

  const { checkMaxRegister, checkSubTitle } = checkCapacity()
  const lang = localStorage.getItem('i18nextLng') as string

  const defaultBackground = Background
  const event = data?.event

  const isDigitalEvent = event?.type === EventType.DIGITAL
  let tz = event?.timezone || defaultTimezone

  if (isDigitalEvent) {
    const { timeZone: clientTimeZone } = Intl.DateTimeFormat().resolvedOptions()
    tz = clientTimeZone
  }

  const { isTheSameDay, startTime, endTime, utcOffset, isTheSameTime } =
    getEventTime(event?.mainLanguage, tz, event?.startEvent, event?.endEvent)

  const fontColor = event?.eventSetting?.fontColor || COLORS.BLUE
  const primaryColor = event?.eventSetting?.primaryColor || COLORS.BLUE
  const bgColor = hexToRgb(event?.eventSetting?.bgColor || COLORS.WHITE)
  const bgColorOpacity = event?.eventSetting?.bgColorOpacity
  const backgroundTransparent = event?.eventSetting?.backgroundTransparent

  const subTitle = checkSubTitle(
    event?.isExpired,
    event?.capacity,
    event?.attendeesCount,
    event?.eventSetting?.isLockedRegister
  )
  const logoWidth = event?.eventSetting?.logoSize?.split('x')[0]
  const isShowPrice =
    event?.eventSetting?.isShowPrice === null ||
    event?.eventSetting?.isShowPrice === true

  const [registerLink, setRegisterLink] = useState<string>('')

  const isTwoSidesLayout =
    event?.eventSetting?.layoutType !== EventLayoutType.CLASSIC

  useEffect(() => {
    if (event?.slug) {
      setRegisterLink(`${Environment.registerEventPage}/events/${event?.slug}`)
    } else {
      setRegisterLink(`${Environment.registerEventPage}/events/${id}`)
    }
  }, [event?.slug])

  if (loading) {
    return <Loading />
  }

  return (
    <div
      className='min-h-[calc(100vh-54px)] md:min-h-[calc(100vh-80px)] my-auto bg-center bg-no-repeat bg-cover relative'
      style={{
        backgroundImage: isTwoSidesLayout
          ? 'none'
          : `url(${
              event?.eventSetting?.backgroundPicture || defaultBackground
            })`,
        color: fontColor,
      }}
    >
      {/* overlay for background */}
      {!isTwoSidesLayout && (
        <div
          className='absolute inset-0 bg-white'
          style={{
            opacity: 1 - Number(backgroundTransparent),
          }}
        />
      )}

      <div
        className={cn(
          'flex bg-white p-5 pb-10 md:p-8 gap-8 min-h-screen lg:h-full flex-col lg:flex-row'
        )}
        style={{
          backgroundColor: isTwoSidesLayout
            ? `rgba(${bgColor?.r}, ${bgColor?.g}, ${bgColor?.b}, ${bgColorOpacity})`
            : 'transparent',
        }}
      >
        {/* Left Section - Image */}
        {isTwoSidesLayout && (
          <div
            className='w-full mt-4 md:mt-0 h-[300px] md:h-[500px] lg:h-auto lg:w-2/5 bg-cover bg-center bg-no-repeat'
            style={{
              backgroundImage: `url(${
                event?.eventSetting?.backgroundPicture || defaultBackground
              })`,
              opacity: 1 - Number(backgroundTransparent),
            }}
          />
        )}

        {/* Right Section - Details */}
        <div
          className={cn(
            'relative',
            isTwoSidesLayout ? 'w-full lg:w-3/5' : 'w-full'
          )}
        >
          <div>
            <div className='relative'>
              {event?.eventSetting?.logo && (
                <img
                  src={event?.eventSetting?.logo}
                  alt='Thumbnail 2'
                  className={cn(
                    'object-cover',
                    logoWidth ? `${logoWidth}px` : 'w-1/2 md:w-1/4'
                  )}
                  width={logoWidth || '0%'}
                />
              )}
              {/* {!event?.isBookable ||
                (event?.price !== 0 && (
                  <div
                    style={{
                      backgroundColor: primaryColor,
                      color: setTextColorBasedOnBackground(primaryColor),
                    }}
                    className='md:flex absolute top-0 right-0 flex items-center justify-start text-white px-4 py-2 rounded-full text-base md:text-lg'
                  >
                    <BadgeCurrency currency={event?.currency} className='' />
                    <span className=''>
                      {priceFormat(event?.price, lang)} / {t('person')}
                    </span>
                  </div>
                ))} */}
            </div>
            <div className='mb-4 md:mb-6'>
              <h1
                className={cn(
                  'mt-4 font-semibold',
                  event?.title && event?.title?.length > 40
                    ? 'text-xl md:text-3xl'
                    : event?.title && event?.title?.length > 20
                    ? 'text-3xl md:text-4xl'
                    : 'text-4xl md:text-5xl'
                )}
              >
                {event?.title}
              </h1>
            </div>

            <div className='flex flex-col gap-2'>
              {!isDigitalEvent ? (
                <>
                  <div className='flex items-center gap-2 justify-start'>
                    <div className='w-[16px]'>
                      <MapPin width='16' height='16' />
                    </div>
                    <p className='text-base'>
                      {event?.hotelName ? `${event?.hotelName}, ` : ''}
                      {transformAddress(
                        event?.address?.street as string,
                        '',
                        event?.address?.city as string,
                        event?.address?.country as string
                      )}
                    </p>
                  </div>
                  <div className='flex items-center gap-2 justify-start	'>
                    <div className='w-[16px]'>
                      <CalendarDays width='16' height='16' />
                    </div>
                    <p className='text-base'>
                      {getEventDateByTimeZone(event?.startEvent, lang, tz)}
                      {!isTheSameDay &&
                        ` - ${getEventDateByTimeZone(
                          event?.endEvent,
                          lang,
                          tz
                        )}`}
                    </p>
                  </div>
                  {isTheSameDay && (
                    <div className='flex items-center gap-2 justify-start'>
                      <Clock width='16' height='16' />
                      <p className='text-base'>
                        {isTheSameTime
                          ? `${startTime} (GMT${utcOffset})`
                          : `${startTime} - ${endTime} (GMT${utcOffset})`}
                      </p>
                    </div>
                  )}
                </>
              ) : (
                <>
                  <div className='flex items-center gap-2 justify-start	'>
                    <CalendarDays width='16' height='16' />
                    <p className='text-base'>
                      {getEventDateByTimeZone(event?.startEvent, lang, tz)}{' '}
                      {!isTheSameDay &&
                        ` - ${getEventDateByTimeZone(
                          event?.endEvent,
                          lang,
                          tz
                        )}`}
                    </p>
                  </div>
                  {isTheSameDay && (
                    <div className='flex items-center gap-2 justify-start'>
                      <Clock width='16' height='16' />
                      <p className='text-base'>
                        {`${startTime} - ${endTime} (GMT${utcOffset})`}
                      </p>
                    </div>
                  )}
                </>
              )}
              {event?.isBookable && event?.price !== 0 && isShowPrice && (
                <div className='flex items-center gap-2 justify-start'>
                  <BadgeCurrency currency={event?.currency} className='' />
                  <span className=''>
                    {priceFormat(event?.price, lang)} / {t('person')}
                  </span>
                </div>
              )}
            </div>
          </div>

          <div className='my-4 pr-4 overflow-x-auto mt-2 attendee-detail scrollbar-custom flex-grow max-h-none event-detail-description-container'>
            {event?.description && (
              <div
                dangerouslySetInnerHTML={{
                  __html: DOMPurify.sanitize(event?.description) as string,
                }}
                className={cn('text-base leading-8')}
              />
            )}
          </div>
          <div className='w-full flex items-center'>
            <CopyToClipBoard value={registerLink} maxWidth='100%' />
          </div>
        </div>
      </div>
    </div>
  )
}

export default AdminEventDetail
